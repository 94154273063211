import React from 'react';
import set from 'lodash/fp/set';
import { FormField, Label, Surface } from '@noloco/components';
import { getColorByIndex } from '@noloco/core/src/utils/colors';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionValid } from '@noloco/core/src/utils/options';
import ColorOptionInput from './ColorOptionInput';

const ensureOptionsHaveNew = (options: any) => [
  ...options.filter((option: any) => option && option.display),
  { display: '' },
];

type Props = {
  className?: string;
  options: any[];
  setOptions: (value: any[]) => void;
  surface: Surface;
};

const OptionEditor = ({ className, options, setOptions, surface }: Props) => {
  const updateOptionValue =
    (index: any) =>
    ({ target: { value } }: any) => {
      if (!value) {
        const newOptions = options.filter(
          (__, optionIndex) => optionIndex !== index,
        );
        return setOptions(ensureOptionsHaveNew(newOptions));
      }
      const nextOptions = set([index, 'display'], value, options);
      setOptions(ensureOptionsHaveNew(nextOptions));
    };

  const updateOptionColor = (index: any) => (value: any) => {
    const nextOptions = set([index, 'color'], value, options);
    setOptions(ensureOptionsHaveNew(nextOptions));
  };

  return (
    <div className={className} data-testid="field-options-editor">
      <Label surface={surface} className="mt-4">
        {getText('data.options.label')}
      </Label>
      <div className="flex flex-col space-y-2">
        {ensureOptionsHaveNew(options).map((option, optionIndex) => (
          <div className="flex w-full space-x-2" key={optionIndex}>
            <ColorOptionInput
              className="mt-1 pt-px"
              data-
              onChange={updateOptionColor(optionIndex)}
              value={(option as any).color || getColorByIndex(optionIndex)}
            />
            <FormField
              className="w-full"
              data-testid="field-options-name-input"
              key={optionIndex}
              onChange={updateOptionValue(optionIndex)}
              value={option.display}
              errorType="below-solid"
              errorMessage={
                option.display && !isOptionValid(option)
                  ? getText('data.options.invalid')
                  : undefined
              }
              placeholder={getText('data.options.new')}
              surface={surface}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OptionEditor;

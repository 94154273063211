// TODO remove the @sentry/react import
import { captureException } from '@sentry/react';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import initial from 'lodash/initial';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import { DateTime } from 'luxon';
import {
  dtToLocalTZMaintainWallTime,
  dtToUTCMaintainWallTime,
} from '@noloco/components/src/utils/dateTimeConversions';
import { AUTH_WRAPPER_ID } from '../constants/auth';
import { FILE, USER } from '../constants/builtInDataTypes';
import { INTERNAL } from '../constants/dataSources';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
  MULTIPLE_OPTION,
  NUMERIC_DATATYPES,
  OBJECT,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { DATETIME_MED, ISO } from '../constants/dateFormatOptions';
import {
  HOURS_MINUTES,
  HOURS_MINUTES_SECONDS,
} from '../constants/durationFormatOptions';
import {
  ARRAY,
  COMBO,
  DATA_PROP,
  GROUP,
  KEY_MAP,
  NODE,
  RAW_DATA_PROP,
  STRING,
  VARIABLE,
} from '../constants/elementPropTypeTypes';
import * as elements from '../constants/elements';
import { LIST, PAGE } from '../constants/elements';
import {
  DATE as DATE_FORMAT,
  EMAIL as EMAIL_FORMAT,
  IP_ADDRESS as IP_ADDRESS_FORMAT,
  OBJECT_FORMATS,
  ObjectFieldFormat,
  TIME,
  URL as URL_FORMAT,
} from '../constants/fieldFormats';
import * as filters from '../constants/filters';
import {
  AFTER,
  AFTER_OR_EQUAL,
  ARRAY_OPERATORS,
  BEFORE,
  BEFORE_OR_EQUAL,
  DOES_NOT_CONTAIN,
  EMAIL,
  EMPTY,
  FALSE,
  GREATER_OR_EQUAL,
  IP_ADDRESS,
  LESS_OR_EQUAL,
  NOT_EMPTY,
  NOT_EQUAL,
  OR,
  Operator,
  TRUE,
  URL,
} from '../constants/operators';
import PRIMITIVE_DATA_TYPES from '../constants/primitiveDataTypes';
import { DATABASE } from '../constants/scopeTypes';
import elementsConfig from '../elements/baseElementConfig';
import DataTypeFields, { DataField } from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import {
  Condition,
  DepValue,
  Element,
  StringPropSegment,
} from '../models/Element';
import { Project } from '../models/Project';
import { DataTypeName } from '../models/ProjectArrayTypeMap';
import { RecordEdge } from '../models/Record';
import StateItem from '../models/StateItem';
import StringPropType from '../models/elementPropTypes/StringPropType';
import ChildElementPropType from '../models/elementPropTypes/comboProps/ChildElementPropType';
import { reduceSubFieldsToQueryObject } from '../queries/data';
import { ensureArray } from './arrays';
import {
  getSingleOptionValueFromDataTypes,
  getStaticValuesFromValuePath,
} from './baseScope';
import cappedMemoize from './cappedMemoize';
import { findPreviewFields } from './dataTypes';
import { formatDateValue, getDateFromValue } from './dates';
import { getDurationFromString } from './durations';
import { formatFieldValue, formatTimeValue } from './fieldValues';
import {
  allowNegative,
  collectionDeps,
  getFieldFromDependency,
  getFieldReverseApiName,
  getFieldReverseName,
} from './fields';
import { mergeFilterIntoWhere } from './filters';
import isIframeFieldAction from './isIframeFieldAction';
import { getText } from './lang';
import { getSubFieldsAsDataFields } from './objects';
import {
  compareValues,
  getInputTypeForOperator,
  getResultForOperator,
  shouldOperatorHaveValue,
} from './operator';
import { isOptionType } from './options';
import { getPagesConfig } from './pages';
import { cleanEdgesNodeFromDepPath } from './queries';
import { transformQueryArgs } from './queries';
import { isMultiField } from './relationships';
import { RECORD_SCOPE } from './scope';
import { flattenStateItem } from './state';

// @ts-expect-error TS(7023): 'getDefaultOptionValue' implicitly has return type... Remove this comment to see the full error message
export const getDefaultOptionValue = (value: any, options: any) => {
  const flattened = value && flattenStateItem(value);
  if (flattened) {
    for (let optionIdx = 0; optionIdx < options.length; optionIdx++) {
      const option = options[optionIdx];
      if (option.value && flattenStateItem(option.value) === flattened) {
        return option;
      } else if (option.options) {
        // @ts-expect-error TS(7022): 'nestedOption' implicitly has type 'any' because i... Remove this comment to see the full error message
        const nestedOption = getDefaultOptionValue(value, option.options);
        if (nestedOption) {
          return nestedOption;
        }
      }
    }
  }
  return null;
};

export const buildFormattedReverseRelateField = (
  relatedField: DataField,
  dataType: DataType,
  relatedType: DataType,
): DataField => {
  const name = getFieldReverseName(relatedField, dataType) as string;
  const apiName = getFieldReverseApiName(relatedField, dataType) as string;

  const isInternalSourceButReverseExternalField =
    relatedField.source !== INTERNAL && relatedType.source?.type === INTERNAL;

  return {
    id: relatedField.id,
    name: name,
    type: dataType.name,
    apiName: apiName,
    display: getText(
      {
        type: (
          relatedField.reverseDisplayName || dataType.display
        ).toLowerCase(),
      },
      'data.fields.relationship',
      relatedField.relationship!,
    ),
    hidden: relatedField.hidden,
    internal: relatedField.internal,
    readOnly: relatedField.readOnly || isInternalSourceButReverseExternalField,
    relatedField,
  };
};

export const getRelatedDataTypes = (
  dataTypes: DataTypes,
  dataTypeName: string,
) =>
  dataTypeName === FILE
    ? []
    : dataTypes.reduce((acc: any, type: any) => {
        const relatedType = dataTypes.getByName(dataTypeName);
        const relatedFields = type.fields.filter(
          (field: any) => field.type === dataTypeName && !field.relatedField,
        );

        acc = acc.concat(
          relatedFields.map((relatedField: any) =>
            buildFormattedReverseRelateField(relatedField, type, relatedType!),
          ),
        );

        return acc;
      }, []);

export const getDataTypeWithRelations = (dataTypes: any, dataType: any) =>
  set(
    'fields',
    new DataTypeFields(
      dataType.fields.concat(
        getRelatedDataTypes(dataTypes, dataType.name).filter(
          (field: any) => field.relatedField.relationship,
        ),
      ),
    ),
    dataType,
  );

export const addRelatedFieldsToDataType = (
  _dataType: DataType,
  dataTypes: DataTypes,
): DataType => {
  dataTypes.forEach((dataType) => {
    dataType.fields
      .getFieldsByRelatedDataTypeName(_dataType.name)
      .forEach((field) => {
        const reverseRelatedField = buildFormattedReverseRelateField(
          field,
          dataType,
          _dataType,
        );

        const existingIndex = _dataType.fields.findIndex(
          ({ name }) => name === reverseRelatedField.name,
        );

        if (existingIndex !== -1) {
          _dataType.fields[existingIndex] = reverseRelatedField;
        } else {
          _dataType.fields.push(reverseRelatedField);
        }
      });
  });

  return _dataType;
};

export const getDataTypesWithRelations = (_dataTypes: any, logger?: any) => {
  const dataTypes = new DataTypes([..._dataTypes]).setupSync();

  dataTypes.forEach((dataType) => {
    Object.entries(dataType.fields.dataTypeNameToRelatedDataTypesMap).forEach(
      ([dataTypeName, fieldNames]) => {
        const relatedType = dataTypes.getByName(dataTypeName);
        if (relatedType) {
          fieldNames.forEach((fieldName) => {
            const field = dataType.fields.getByName(fieldName);

            if (field && !field.relatedField) {
              const reverseField = buildFormattedReverseRelateField(
                field,
                dataType,
                relatedType,
              );

              if (
                relatedType.name !== dataType.name &&
                !relatedType.fields.getById(field.id)
              ) {
                if (logger) {
                  logger.warn(
                    `Pushed missing reverse field ${reverseField.name} onto type ${relatedType.id} (${relatedType.name}).`,
                  );
                }
                relatedType.fields.push(reverseField);
              } else if (
                relatedType.name === dataType.name &&
                !dataType.fields.getByName(reverseField.name)
              ) {
                if (logger) {
                  logger.warn(
                    `Pushed missing reverse field ${reverseField.name} onto type ${relatedType.id} (${relatedType.name}) (self relating).`,
                  );
                }
                dataType.fields.push(reverseField);
              }
            }
          });
        }
      },
    );
  });

  dataTypes.update();

  return dataTypes;
};

export const getDataTypeByName = (dataTypes: any, name: any) =>
  dataTypes.getByName(name) || dataTypes.getByApiName(name);

export const safelyAppendPath = (path: any, nextPath: any) =>
  !path || path === '' ? nextPath : `${path}.${nextPath}`;

export const getConditionDataItems = (conditionsObject = {}) => {
  const items: any = [];
  // @ts-expect-error TS(2345): Argument of type '({ conditions }: { conditions: a... Remove this comment to see the full error message
  Object.values(conditionsObject).forEach(({ conditions }) =>
    ensureArray(conditions).forEach((andCondition: any) =>
      ensureArray(andCondition).forEach((condition: any) => {
        if (condition.field) {
          items.push(condition.field);
        }

        ensureArray(condition.value).forEach((valueItem: any) => {
          if (valueItem.data) {
            items.push(valueItem.data);
          }
        });
      }),
    ),
  );
  return items;
};

const extractConditionDependencies = (conditionsObject = {}) =>
  getConditionDataItems(conditionsObject);

export const getVisibilityCustomRulesDataItems = (customRules: any[] = []) => {
  if (!customRules || !Array.isArray(customRules)) {
    return [];
  }

  const items: any = [];
  customRules.forEach((andCondition: any[]) =>
    ensureArray(andCondition).forEach((condition: any) => {
      if (condition.field) {
        items.push(condition.field);
      }
      ensureArray(condition.value).forEach((valueItem: any) => {
        if (valueItem.data) {
          items.push(valueItem.data);
        }
      });
    }),
  );
  return items;
};

export const getDataItemsForPropsShape = (
  propsShape: any,
  elementBaseProps: any,
  element: any,
  dataTypes = [],
  includeSelf = true,
) => {
  let dataItems: any = [];
  const handleDataItemProp = (dataItem: any) => {
    if (dataItem) {
      dataItems.push(dataItem);
    }
  };

  const handleDataStringProp = (dataProp: any) => {
    if (Array.isArray(dataProp)) {
      dataProp.forEach(({ data }) => {
        if (data) {
          handleDataItemProp(data);
        }
      });
    }
  };

  Object.entries(propsShape || {}).forEach(([propName, propDefinition]) => {
    if (elementBaseProps) {
      const prop = elementBaseProps[propName];
      if ((propDefinition as any).extractPropTypesDependencies) {
        dataItems = (propDefinition as any).extractPropTypesDependencies(
          prop,
          dataItems,
          elementBaseProps,
          propsShape,
          element,
          dataTypes,
          includeSelf,
        );
      } else {
        switch ((propDefinition as any).type) {
          case RAW_DATA_PROP:
          case DATA_PROP: {
            if (prop) {
              handleDataItemProp(prop);
            }
            break;
          }
          case ARRAY: {
            if (!Array.isArray(prop)) {
              break;
            }
            const arrayDeps = ensureArray(prop).reduce(
              (nestedDeps, nestedBaseProps) => [
                ...nestedDeps,
                ...getDataItemsForPropsShape(
                  (propDefinition as any).shape,
                  nestedBaseProps,
                  element,
                  dataTypes,
                  includeSelf,
                ),
              ],
              [],
            );
            dataItems = [...dataItems, ...arrayDeps];
            break;
          }
          case NODE: {
            if (!Array.isArray(prop)) {
              break;
            }
            const arrayDeps = ensureArray(prop).reduce(
              (nestedDeps, nestedElement) => [
                ...nestedDeps,
                ...findDependencies(nestedElement, dataTypes, false),
              ],
              [],
            );
            dataItems = [...dataItems, ...arrayDeps];
            break;
          }
          case COMBO: {
            if (propDefinition instanceof ChildElementPropType) {
              const comboDeps = findDependencies(prop, dataTypes, false);
              dataItems = [...dataItems, ...comboDeps];
              break;
            }
            const comboDeps = getDataItemsForPropsShape(
              (propDefinition as any).shape,
              prop,
              element,
              dataTypes,
              includeSelf,
            );
            dataItems = [...dataItems, ...comboDeps];
            break;
          }
          case KEY_MAP: {
            if (prop && typeof prop === 'object') {
              const keyMapDeps = Object.values(prop).reduce(
                (acc, propValue) => [
                  // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
                  ...acc,
                  ...getDataItemsForPropsShape(
                    (propDefinition as any).shape,
                    propValue,
                    element,
                    dataTypes,
                    includeSelf,
                  ),
                ],
                [],
              );
              // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
              dataItems = [...dataItems, ...keyMapDeps];
            }
            break;
          }
          case GROUP: {
            const comboDeps = getDataItemsForPropsShape(
              (propDefinition as any).shape,
              elementBaseProps,
              element,
              dataTypes,
              includeSelf,
            );
            dataItems = [...dataItems, ...(comboDeps || [])];
            break;
          }
          case VARIABLE: {
            const comboDeps = getDataItemsForPropsShape(
              {
                label: new StringPropType(),
                value: (propDefinition as any).propType,
              },
              prop,
              element,
              dataTypes,
              includeSelf,
            );
            dataItems = [...dataItems, ...comboDeps];
            break;
          }
          case STRING: {
            handleDataStringProp(prop);
            break;
          }
          default:
            break;
        }
      }
    }
  });

  return dataItems;
};

const extractPropTypesDependencies = (
  propsShape: any,
  elementBaseProps: any,
  element: any,
  dataTypes: any,
  includeSelf = true,
) => {
  try {
    let dependencies = getDataItemsForPropsShape(
      propsShape,
      elementBaseProps,
      element,
      dataTypes,
      includeSelf,
    );

    if (elementBaseProps) {
      Object.entries(propsShape || {})
        .map(([propKey, propDefinition]) => {
          if ((propDefinition as any).type === NODE) {
            return elementBaseProps[propKey];
          }
          return null;
        })
        .filter(Boolean)
        .forEach((prop) => {
          if (prop && Array.isArray(prop)) {
            const nestedDeps = prop.reduce(
              (nestedDepsAcc, propChild) => [
                ...nestedDepsAcc,
                ...findDependencies(propChild, dataTypes),
              ],
              [],
            );
            dependencies = [...dependencies, ...nestedDeps];
          }
        });
    }

    return dependencies;
  } catch (e) {
    captureException(e);
    console.error(e);
    return [];
  }
};

export const extractDataListDependencies = (
  element: Element | undefined,
  listProps = {},
  deps: DepValue[] = [],
  dataTypes: DataTypes = new DataTypes([]),
  includeSelf = true,
) => {
  let dependencies = [...deps];
  const defaultFilter = (listProps as any).filter;

  const filterHasValidResult = (customFilter: any) =>
    customFilter.result && Array.isArray(customFilter.result);

  const pushDependencyForCustomFilter = (customFilter: any) =>
    customFilter.result
      .filter((segment: any) => segment.data)
      .forEach((segment: any) => {
        dependencies.push(segment.data);
      });

  if (listProps && (listProps as any).customFilters) {
    ensureArray((listProps as any).customFilters).forEach(
      (customFilter: any) => {
        if (filterHasValidResult(customFilter)) {
          pushDependencyForCustomFilter(customFilter);
        } else if (
          customFilter.branches &&
          Array.isArray(customFilter.branches)
        ) {
          customFilter.branches.forEach((filterBranch: any) => {
            if (filterBranch.filters && Array.isArray(filterBranch.filters)) {
              filterBranch.filters.forEach((filter: any) => {
                if (filterHasValidResult(filter)) {
                  pushDependencyForCustomFilter(filter);
                }
              });
            }
          });
        } else if (
          customFilter.filters &&
          Array.isArray(customFilter.filters)
        ) {
          customFilter.filters.forEach((filter: any) => {
            if (filterHasValidResult(filter)) {
              pushDependencyForCustomFilter(filter);
            }
          });
        }
      },
    );
  }

  if (defaultFilter && defaultFilter.id) {
    // Because "COLLECTION"s have props that resolve to row items we need to proxy those here
    // by re-finding the deps for itself, but making sure we don't infinitely loop
    const selfDeps =
      element && element.type !== LIST && includeSelf
        ? findDependentValues(element.id, element, dataTypes, false)
        : [];
    const nestedValues = ensureArray(element?.children).reduce(
      (nestedAcc: any, child: any) => [
        ...nestedAcc,
        ...findDependentValues((element as Element).id, child, dataTypes),
      ],
      selfDeps,
    );

    const nestedDeps = nestedValues.map(
      (nestedStateItem: any) =>
        new StateItem({
          ...nestedStateItem,
          id: defaultFilter.id,
          path: safelyAppendPath(defaultFilter.path, nestedStateItem.path),
        }),
    );
    // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
    const { dataType, limit, orderBy } = listProps;

    // @ts-expect-error TS2554: Expected 3-4 arguments, but got 1.
    const resolvedLimit = limit ? resolveDataValue(limit) : null;
    const queryArgs = {
      ...(orderBy ? { orderBy } : {}),
      ...(resolvedLimit ? { first: resolvedLimit } : {}),
    };

    const dataTypesMap = new DataTypes(dataTypes);

    const collectionDataType = dataTypesMap.getByName(dataType);
    if (!collectionDataType) {
      return dependencies;
    }

    const collectionField = getFieldFromDependency(
      defaultFilter.path.split('.'),
      collectionDataType,
      dataTypesMap,
    );

    if (!collectionField || !isMultiField(collectionField.field)) {
      return dependencies;
    }

    dependencies.push(
      new StateItem({
        ...defaultFilter,
        dataType,
        args: queryArgs,
      }),
    );

    // To ensure that deeply nested collections always request the ID and UUID of the parent-type object
    const parentPath = initial(defaultFilter.path.split('.')).join('.');
    ['id', 'uuid'].forEach((path) => {
      dependencies.push(
        new StateItem({
          ...defaultFilter,
          dataType: TEXT,
          path: safelyAppendPath(parentPath, path),
        }),
      );
    });

    dependencies = dependencies.concat(nestedDeps);
  }

  if (
    defaultFilter &&
    (listProps as any).dataType
    // (defaultFilter.id === parentId ||
    //   (!defaultFilter.id && element.id === parentId))
  ) {
    ['id', 'uuid'].forEach((path) => {
      dependencies.push(
        new StateItem({
          ...defaultFilter,
          dataType: TEXT,
          path: safelyAppendPath(defaultFilter.path, `edges.node.${path}`),
        }),
      );
    });
  }

  return dependencies;
};

// @ts-expect-error TS(7023): 'getDeepestDataProperty' implicitly has return typ... Remove this comment to see the full error message
const getDeepestDataProperty = (dataItem: any) =>
  dataItem.property ? getDeepestDataProperty(dataItem.property) : dataItem;

export const getDataItemDataType = (dataItem: any) =>
  dataItem ? (getDeepestDataProperty(dataItem) || {}).dataType : null;

export const getDataTypesKey: (dataTypes: DataTypes) => string = cappedMemoize(
  (dataTypes) => `${Date.now()}:${dataTypes.updatedAt}`,
  {
    maxKeys: 2,
    // This should change every time we mutate the dateTypes model
    getKey: ([dataTypes]) => {
      return String(dataTypes.updatedAt);
    },
  },
);

export const findDependencies = cappedMemoize(
  (element, dataTypes, includeSelf) => {
    if (!element) {
      return [];
    }

    const elementConfig = elementsConfig[element.type];

    if (!elementConfig) {
      return [];
    }

    let dependencies: any = [];
    dependencies = dependencies.concat(
      extractPropTypesDependencies(
        elementConfig.props,
        element.props,
        element,
        dataTypes,
        includeSelf,
      ),
    );

    dependencies = dependencies.concat(
      extractConditionDependencies(element.conditions),
    );

    const customRules = get(element, 'visibilityRules.customRules');
    if (customRules) {
      const customRuleDataItems =
        getVisibilityCustomRulesDataItems(customRules);
      dependencies = dependencies.concat(customRuleDataItems);
    }

    if (element.type === LIST && element.props.filter) {
      try {
        dependencies = extractDataListDependencies(
          element,
          element.props,
          dependencies,
          dataTypes,
          includeSelf,
        );
      } catch (e) {
        captureException(e);
        console.error(e);
      }
    }

    return [
      ...dependencies,
      ...ensureArray(element.children).reduce(
        (depAcc: any, child: any) => [
          ...depAcc,
          ...findDependencies(child, dataTypes),
        ],
        [],
      ),
    ];
  },
  {
    maxKeys: 350,
    getKey: ([element, dataTypes, includeSelf]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return JSON.stringify({ element, dtKey, includeSelf });
    },
  },
);

export const findDependentValues = (
  parentId: string,
  element: Element,
  dataTypes: DataTypes,
  includeSelf = true,
) =>
  findDependentValuesByParentIds([parentId], element, dataTypes, includeSelf);

export const findDependentValuesByParentIds = cappedMemoize(
  (parentIds, element, dataTypes, includeSelf = true) => {
    if (!element) {
      return [];
    }

    const elementConfig = elementsConfig[element.type];

    let dependencies = findDependencies(element, dataTypes, includeSelf).filter(
      (dataItem: any) => dataItem && parentIds.includes(dataItem.id),
    );

    if (!elementConfig) {
      return [];
    }

    if (
      element.type === PAGE &&
      parentIds.includes(element.id) &&
      element.props.dataType &&
      element.props.dataProperty
    ) {
      ['id', 'uuid'].forEach((path) => {
        dependencies.push(
          // @ts-expect-error TS(2345): Argument of type '{ id: any; dataType: string; sou... Remove this comment to see the full error message
          new StateItem({
            id: element.id,
            dataType: TEXT,
            source: DATABASE,
            path,
          }),
        );
      });
    }

    return dependencies;
  },
  {
    maxKeys: 350,
    getKey: ([parentIds, element, dataTypes, includeSelf]) => {
      const dtKey = getDataTypesKey(dataTypes);
      return JSON.stringify({ parentIds, element, dtKey, includeSelf });
    },
  },
);

export const mapDepPathToApiPath = (
  dependencyPath: any,
  dataType: any,
  dataTypes: any,
) => {
  return dependencyPath.reduce((newPath: any, pathSlice: any, index: any) => {
    if (['_columns', 'edges', 'node'].includes(pathSlice)) {
      return [...newPath, pathSlice];
    }

    const fieldDependency = getFieldFromDependency(
      dependencyPath.slice(0, index + 1),
      dataType,
      dataTypes,
    );
    if (!fieldDependency) {
      return [...newPath, pathSlice];
    }
    const { field } = fieldDependency;

    if (field.apiName && !field.apiName.includes('.')) {
      return [...newPath, field.apiName];
    }

    return [...newPath, pathSlice];
  }, []);
};

const findElementById = (elementId: any, elements = []) => {
  let q = [...elements];

  while (q.length > 0) {
    const el = q.shift();
    if (!el) {
      return null;
    }

    if (
      (el as any).id === elementId ||
      `${(el as any).id}:VIEW` === elementId
    ) {
      return el;
    }

    if ((el as any).children && Array.isArray((el as any).children)) {
      // @ts-expect-error TS(2769): No overload matches this call.
      q = q.concat([...(el as any).children]);
    }
  }

  return null;
};

const getElementDatatypeName = (element: any) => {
  switch (element.type) {
    case elements.CHART:
    case elements.COLLECTION:
    case elements.VIEW:
      return get(element, 'props.dataList.dataType');
    case elements.LIST:
    case elements.FORM_V2:
    case elements.PAGE:
      return get(element, 'props.dataType');
    default:
      return null;
  }
};

const getValuePathRootDataType = (
  valuePath: any,
  elements: any,
  dataTypes: any,
) => {
  const rootId = first(valuePath);
  if (rootId === AUTH_WRAPPER_ID) {
    return dataTypes.getByName(USER);
  }

  const valuePathElement = findElementById(rootId, elements);

  if (!valuePathElement) {
    return null;
  }

  const dataTypeName = getElementDatatypeName(valuePathElement);

  if (!dataTypeName) {
    return null;
  }

  return dataTypes.getByName(dataTypeName);
};

export const convertValuePathToApiPath = (valuePath: any, project: Project) => {
  if (!project) {
    return valuePath;
  }

  const { isV2, pagesPath } = getPagesConfig(
    project.elements,
    project.settings,
  );
  const elements = isV2 ? project.elements : get(project, pagesPath);

  const rootDataType = getValuePathRootDataType(
    valuePath,
    elements,
    project.dataTypes,
  );

  if (!rootDataType) {
    return valuePath;
  }

  const mappedValuePath = [
    first(valuePath),
    ...mapDepPathToApiPath(valuePath.slice(1), rootDataType, project.dataTypes),
  ];

  return mappedValuePath;
};

const setDefaultDependenciesAtPath = (object: any, path: string[] = []) => {
  const objectWithId = set([...path, 'id'], true, object);

  if (path.length === 0) {
    return objectWithId;
  }

  return set([...path, 'uuid'], true, objectWithId);
};

export const transformDepsToQueryObject = (
  dataType: any,
  dataTypesWithRelations: any,
  dependencies: any,
  pathPrefix = [],
) =>
  dependencies.reduce((queryObject: any, dep: any) => {
    const fieldDependency = getFieldFromDependency(
      dep.path.split('.'),
      dataType,
      dataTypesWithRelations,
    );

    if (!fieldDependency) {
      return queryObject;
    }
    const { field, parent } = fieldDependency;

    const cleanDepPath = dep.path.replace(/_columns/g, 'edges.node').split('.');

    const apiDepPath = mapDepPathToApiPath(
      cleanDepPath,
      dataType,
      dataTypesWithRelations,
    );

    const splitPath = [...pathPrefix, ...apiDepPath];
    const isRelationshipField = !!(field.relationship || field.relatedField);
    const isObjectField = field.type === OBJECT;

    const valueIsAlreadySet =
      get(queryObject, splitPath) &&
      ((!isRelationshipField && !isObjectField) ||
        get(queryObject, [
          ...splitPath,
          ...(isMultiField(field) ? ['edges', 'node', 'id'] : ['id']),
        ]));

    if (!valueIsAlreadySet) {
      if (!isRelationshipField) {
        const object = set(
          splitPath,
          field.type === OBJECT ? reduceSubFieldsToQueryObject(field) : true,
          queryObject,
        );
        if (collectionDeps.includes(last(splitPath))) {
          return object;
        }

        const initialSplitPath =
          get(parent, ['type']) === OBJECT
            ? initial(initial(splitPath))
            : initial(splitPath);
        const initialObject =
          first(initialSplitPath) === 'edges'
            ? object
            : setDefaultDependenciesAtPath(object);

        const objectWithId = initialSplitPath.reduce(
          (intermediateObject, _, index) => {
            const child = splitPath[index + 1];

            if (['edges', 'node'].includes(child)) {
              return intermediateObject;
            }

            const intermediatePath = [...splitPath.slice(0, index + 1)];
            return setDefaultDependenciesAtPath(
              intermediateObject,
              intermediatePath,
            );
          },
          initialObject,
        );
        return set([...initialSplitPath, 'uuid'], true, objectWithId);
      }

      if (isRelationshipField) {
        if (!isMultiField(field)) {
          return set(
            splitPath,
            { id: true, uuid: true, ...get(queryObject, splitPath, {}) },
            queryObject,
          );
        } else {
          return set(
            splitPath,
            {
              edges: {
                node: {
                  id: true,
                  uuid: true,
                  ...get(queryObject, [...splitPath, 'edges', 'node'], {}),
                },
              },
              pageInfo: {
                startCursor: true,
                endCursor: true,
                hasNextPage: true,
                hasPreviousPage: true,
              },
              ...(dep.args ? { __args: transformQueryArgs(dep.args) } : {}),
            },
            queryObject,
          );
        }
      }
    } else if (dep.args && parent?.type !== OBJECT) {
      return set(
        splitPath,
        {
          ...get(queryObject, splitPath, {}),
          __args: transformQueryArgs(dep.args),
        },
        queryObject,
      );
    }

    return queryObject;
  }, {});

export const expandDataTypes = (dataType: any) => {
  switch (dataType) {
    case DECIMAL:
    case INTEGER:
    case TEXT:
      return [DECIMAL, INTEGER, TEXT, SINGLE_OPTION];
    default:
      return [dataType];
  }
};

export const getDataItemType = (dataItem = {}) => (dataItem as any).dataType;

export const formatBooleanValue = (value: any) => {
  if (value === true || value === false) {
    return value;
  }

  if (value && String(value).toLowerCase() === 'true') {
    return true;
  }
  if (value && String(value).toLowerCase() === 'false') {
    return false;
  }

  return Boolean(value);
};

export const formatValueForField = (
  value: any,
  field: any,
  display = false,
  displayOptions: { locale?: any; options?: { format?: any } } = {},
): null | string | number | Date | Duration => {
  if (isNil(value)) {
    return null;
  }

  const { options = [], type, typeOptions } = field;

  if (type === DATE) {
    const dateFieldFormat = get(field, 'typeOptions.format');
    const timeZone = get(field, 'typeOptions.timeZone');

    let parsedDate = getDateFromValue(value);
    const isDateOnly = dateFieldFormat === DATE_FORMAT;

    if (!parsedDate) {
      return null;
    }

    if (!display) {
      return parsedDate.toJSDate();
    }

    if (isDateOnly) {
      parsedDate = parsedDate.toUTC();
    }

    if (timeZone) {
      parsedDate = parsedDate.setZone(timeZone);
    }

    const defaultFormat =
      dateFieldFormat === DATE_FORMAT
        ? DateTime.DATE_SHORT
        : DateTime.DATETIME_SHORT;

    const { options: { format = defaultFormat } = {} } = displayOptions;
    if (format === ISO) {
      return parsedDate.toISO();
    }
    return parsedDate.toLocaleString(format);
  }

  if (type === DURATION) {
    const parsedDuration = getDurationFromString(value);

    if (!parsedDuration || !parsedDuration.isValid) {
      return null;
    }

    if (!display) {
      return parsedDuration;
    }

    if (typeOptions?.format === TIME && displayOptions.locale) {
      return formatTimeValue(value as string, displayOptions.locale);
    }

    const { options: { format = HOURS_MINUTES_SECONDS } = {} } = displayOptions;
    return parsedDuration.toFormat(format);
  }

  if (type === BOOLEAN) {
    const formattedValue = formatBooleanValue(value);

    if (display) {
      return getText('values.BOOLEAN', formattedValue);
    }
    return formattedValue;
  }

  if (type === DECIMAL) {
    return parseFloat(value);
  }

  if (type === INTEGER) {
    return parseInt(value, 10);
  }

  if (type === SINGLE_OPTION) {
    const option = options.find(
      (op: any) => op.name === value || op.display === value,
    );
    if (!option) {
      return null;
    }

    return display ? option.display : option.name;
  }

  if (type === MULTIPLE_OPTION) {
    const splitValue = String(value)
      .split(',')
      .map((s) => s.trim())
      .filter(Boolean);

    const valueOptions = options.filter(
      (op: any) =>
        splitValue.includes(op.name) || splitValue.includes(op.display),
    );

    if (display) {
      return valueOptions.map((o: any) => o.display).join(', ');
    }

    return valueOptions.map((o: any) => o.name);
  }

  if (type === OBJECT) {
    return value;
  }

  return String(value);
};

const findOptionFieldByValuePath = (
  dataTypes: DataTypes,
  valuePath: string[],
  optionType = SINGLE_OPTION,
) =>
  findMatchingFieldByValuePath(
    dataTypes,
    valuePath,
    (field) => field.type === optionType,
  );

const findMatchingFieldByValuePath = (
  dataTypes: DataTypes,
  valuePath: string[],
  matcher: (field: DataField) => boolean,
) => {
  const fieldName = last<string>(valuePath);
  if (!fieldName) {
    return {};
  }

  for (let i = 0; i < dataTypes.length; i++) {
    const dataType = dataTypes[i];
    const field = dataType.fields.getByName(fieldName);
    if (field && matcher(field)) {
      return { field, dataType };
    }
  }
  return {};
};

export const formatValue = (
  value: any,
  dataItemType: any,
  dataItem: StringPropSegment | undefined,
  rawValue = true,
  dataTypes: DataTypes,
  valuePath: string[] = [],
  multiField = false,
  dataTypeName?: DataTypeName,
  localeName?: string,
  locale?: Locale,
): any => {
  const rootDataType = dataTypeName && dataTypes.getByName(dataTypeName);
  const { field } = (
    rootDataType && dataItem && dataItem.data
      ? (getFieldFromDependency(
          dataItem.data.path.split('.'),
          rootDataType,
          dataTypes,
        ) ?? {})
      : {}
  ) as { field?: DataField };

  if (multiField) {
    if (rawValue) {
      return value;
    }

    const filtered = ensureArray(value).filter(Boolean);
    return filtered
      .map((v) =>
        formatValue(
          v,
          dataItemType,
          dataItem,
          rawValue,
          dataTypes,
          valuePath,
          multiField,
          dataTypeName,
          localeName,
          locale,
        ),
      )
      .join(', ');
  }

  if (!rawValue && field && !isNil(value)) {
    const formattedValue = formatFieldValue(
      value,
      field,
      undefined,
      localeName,
      locale,
    );

    if (formattedValue !== undefined) {
      return formattedValue;
    }
  }

  if (dataItemType === DATE) {
    if (rawValue) {
      return value;
    }

    if (value) {
      const parsedDate = getDateFromValue(value);

      if (parsedDate) {
        // @ts-expect-error TS(2339): Property 'options' does not exist on type '{}'.
        const { options: { format = DATETIME_MED } = {} } = dataItem;

        return field
          ? formatDateValue(value, field, get(dataItem, 'options'))
          : format === ISO
            ? parsedDate.toISO()
            : parsedDate.toLocaleString(format);
      }
    }
  }

  if (dataItemType === DURATION) {
    if (rawValue) {
      return value;
    }

    if (value) {
      const parsedDuration = getDurationFromString(value);
      // @ts-expect-error TS(2576): Property 'invalid' does not exist on type 'Duratio... Remove this comment to see the full error message
      if (parsedDuration && !parsedDuration.invalid) {
        // @ts-expect-error TS(2339): Property 'options' does not exist on type '{}'.
        const { options: { format = HOURS_MINUTES } = {} } = dataItem;
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
        return parsedDuration.toLocaleString(format);
      }
    }
  }

  if (dataItemType === BOOLEAN) {
    const formattedValue = formatBooleanValue(value);
    if (!rawValue) {
      return getText('values.BOOLEAN', formattedValue);
    }
    return formattedValue;
  }

  if (dataItemType === DECIMAL || dataItemType === INTEGER) {
    if (isNil(value)) {
      return null;
    }

    if (rawValue || !field) {
      return dataItemType === DECIMAL ? parseFloat(value) : parseInt(value, 10);
    }

    return formatFieldValue(
      Number(value),
      field,
      undefined,
      localeName,
      locale,
    );
  }

  if (dataItemType === SINGLE_OPTION) {
    if (rawValue || !value) {
      return value;
    }
    if (dataTypes) {
      const { field } = findOptionFieldByValuePath(
        dataTypes,
        valuePath,
        SINGLE_OPTION,
      );
      if (field) {
        const option = ensureArray(field.options).find(
          ({ name }: any) => name === value,
        );
        if (option) {
          return option.display;
        }
      }
    }
  }

  if (dataItemType === MULTIPLE_OPTION) {
    if (rawValue || !value) {
      return value;
    }
    if (dataTypes) {
      const { field } = findOptionFieldByValuePath(
        dataTypes,
        valuePath,
        MULTIPLE_OPTION,
      );
      if (field) {
        const options = ensureArray(field.options).filter(({ name }: any) =>
          value.includes(name),
        );

        return options.map((option: any) => option.display).join(', ');
      }
    }
  }

  if (dataItemType !== ARRAY) {
    if (value && Array.isArray(value)) {
      if (value.length > 0 && typeof value[0] === 'object' && value[0].id) {
        return value.map(({ id }) => parseInt(id, 10));
      } else if (value.length === 0) {
        return undefined;
      }
    }

    if (value && typeof value === 'object' && value.id) {
      return value.id;
    }

    // If the value is empty besides the scope data type
    if (
      value &&
      typeof value === 'object' &&
      value._dataType &&
      Object.keys(value).length === 1
    ) {
      return undefined;
    }

    if (value && typeof value === 'object' && value.edges) {
      if (Array.isArray(value.edges)) {
        return value.edges
          .map((edge: RecordEdge) => edge.node?.id)
          .filter(Boolean)
          .map((id: string) => parseInt(id, 10));
      }
    }
  }

  if (!rawValue && Array.isArray(value)) {
    return value.join(', ');
  }

  return value === undefined ? null : value;
};

const getValueFromSources = (
  valuePath: string[],
  dataSources: Record<string, any>,
  dataTypes: DataTypes,
) => {
  const dataId = first(valuePath);

  if (dataId === SINGLE_OPTION) {
    return getSingleOptionValueFromDataTypes(valuePath, dataTypes);
  }

  if (dataId === 'values') {
    return getStaticValuesFromValuePath(valuePath);
  }

  for (let i = 0; i < dataSources.length; i++) {
    const value = get(dataSources[i], valuePath);
    if (value !== undefined) {
      return value;
    }
  }

  return undefined;
};

export const isPrimitiveType = (dataType: any) =>
  PRIMITIVE_DATA_TYPES.includes(dataType);

export const getFullValuePath = (scopeItem: any) => {
  const { id, path } = scopeItem;
  return [id, path].filter(Boolean).join('.');
};

const resolveSingleOptionSubField = (dataItem: any, project: Project) => {
  const [dataTypeName, fieldName, subFieldName, ...rest] =
    dataItem.data.path.split('.');

  const dataType = project.dataTypes.getByName(dataTypeName);
  if (!dataType) {
    return '';
  }

  const field = dataType.fields.getByName(fieldName);
  if (!field) {
    return '';
  }

  const subField = getSubFieldsAsDataFields(field).find(
    ({ name }) => name === subFieldName,
  );
  if (!subField || !subField.options) {
    return '';
  }

  const optionDisplay = rest.join('.');
  const option = subField.options.find(
    ({ display }) => display === optionDisplay,
  );

  return option?.display || '';
};

export const resolveSingleDataItem = (
  dataItem: StringPropSegment,
  scope: Record<string, any>,
  project: Project,
  rawValues = false,
  localeName?: string,
  locale?: Locale,
) => {
  if (!dataItem.data) {
    return null;
  }

  const dataItemType = getDataItemType(dataItem.data);

  if (OBJECT_FORMATS.includes(dataItem.data.id as ObjectFieldFormat)) {
    return resolveSingleOptionSubField(dataItem, project);
  }

  const valuePathStr = getFullValuePath(dataItem.data);
  const valuePath = valuePathStr.split('.');
  const mappedValuePath = convertValuePathToApiPath(valuePath, project);
  const value = getValueFromSources(
    mappedValuePath,
    [scope],
    project.dataTypes,
  );

  const dataTypeName = get(scope, dataItem.data.id, {})._dataType;

  if (value === undefined) {
    return undefined;
  }

  return formatValue(
    value,
    dataItemType,
    dataItem,
    rawValues,
    project.dataTypes,
    valuePath,
    false,
    dataTypeName,
    localeName,
    locale,
  );
};

const formatDataValueSegment = (
  dataItem: StringPropSegment,
  scope: Record<string, any>,
  project: Project,
  rawValues: any,
  localeName?: string,
  locale?: Locale,
) => {
  if (dataItem.text) {
    return dataItem.text;
  }

  if (dataItem.data) {
    return resolveSingleDataItem(
      dataItem,
      scope,
      project,
      rawValues,
      localeName,
      locale,
    );
  }
  return '';
};

const MAX_NUM_LENGTH = String(Number.MAX_SAFE_INTEGER).length;

export const resolveDataValue = (
  dataValue: any,
  scope: any,
  project: any,
  rawValues = false,
  localeName?: string,
  locale?: Locale,
) => {
  if (!Array.isArray(dataValue)) {
    if (typeof dataValue === 'object') {
      return null;
    }
    return dataValue;
  }

  let value;
  if (dataValue.length === 1) {
    value = formatDataValueSegment(
      dataValue[0],
      scope,
      project,
      rawValues,
      localeName,
      locale,
    );
  } else {
    value = dataValue
      .map((dataItem) =>
        formatDataValueSegment(
          dataItem,
          scope,
          project,
          rawValues,
          localeName,
          locale,
        ),
      )
      .join('');
  }

  if (!value) {
    return value;
  }

  const stringValue = String(value).trim();

  if (
    !isNaN(value) &&
    !Array.isArray(value) &&
    stringValue !== '' &&
    stringValue.length < MAX_NUM_LENGTH &&
    typeof value !== 'boolean' &&
    !stringValue.startsWith('+')
  ) {
    return parseFloat(value);
  }

  return value;
};

export const formatWhereConditionField = (field: any, result: any) => {
  if (
    (field.type === INTEGER || field.type === DECIMAL) &&
    !isNil(result) &&
    !Array.isArray(result)
  ) {
    return parseFloat(result);
  }

  return result;
};

export const findFieldFromFilterName = (
  fieldFilterName: string,
  dataType: DataType | undefined,
) => {
  if (!dataType) {
    return undefined;
  }

  const field = dataType.fields.find((field) => {
    if (field.relationship) {
      return `${field.name}Id` === fieldFilterName;
    }

    if (field.relatedField) {
      return `${field.relatedField.reverseName}Id` === fieldFilterName;
    }

    if (field.type === OBJECT) {
      const [fieldName] = fieldFilterName.split('.');

      return field.name === fieldName;
    }

    return field.name === fieldFilterName;
  });

  if (field?.type === OBJECT) {
    const [_, subFieldName] = fieldFilterName.split('.');
    if (!subFieldName) {
      return field;
    }

    return getSubFieldsAsDataFields(field).find(
      (subField) => subField.name === subFieldName,
    );
  }

  return field;
};

export const formatWhereCondition = (whereCondition: any, dataType: any) => {
  const {
    field: fieldName,
    operator,
    result,
    branches,
    filters,
  } = whereCondition;

  if (operator === OR) {
    return {
      field: OR,
      operator: OR,
      branches: ensureArray(branches).map((branch: any) => branch.filters),
    };
  }

  if (!fieldName) {
    return null;
  }

  const field = findFieldFromFilterName(fieldName, dataType);

  if (!field) {
    return null;
  }

  if (ARRAY_OPERATORS.includes(operator)) {
    const formatted: any = formatFilterAndMergeCustomFilters(
      ensureArray(filters),
      dataType,
    );

    return {
      field: fieldName,
      operator,
      result: formatted[fieldName],
    };
  }

  return {
    field: fieldName,
    operator,
    ...(getInputTypeForOperator(operator)
      ? {
          result: formatWhereConditionField(
            field,
            getResultForOperator(
              operator,
              result !== '' ? result : undefined,
              field,
            ),
          ),
        }
      : {}),
  };
};

export const filterWhereCondition = (whereCondition: any) => {
  if (!whereCondition) {
    return false;
  }
  const { operator, result, branches } = whereCondition;
  return (
    (operator === OR && Array.isArray(branches)) ||
    (ARRAY_OPERATORS.includes(operator) && Array.isArray(filters)) ||
    !shouldOperatorHaveValue(operator) ||
    !isNil(result)
  );
};

const getEquivalentFilter = (operator: Operator, result: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const equivalentFilter = filters[operator];
  if (equivalentFilter) {
    return { equivalentFilter, not: false, result };
  }

  switch (operator) {
    case TRUE:
      return { equivalentFilter: filters.EQUAL, not: false, result: true };
    case FALSE:
      return { equivalentFilter: filters.EQUAL, not: false, result: false };
    case EMPTY:
      return { equivalentFilter: filters.EQUAL, not: false, result: null };
    case NOT_EMPTY:
      return { equivalentFilter: filters.EQUAL, not: true, result: null };
    case NOT_EQUAL:
      return { equivalentFilter: filters.EQUAL, not: true, result };
    case DOES_NOT_CONTAIN:
      return { equivalentFilter: filters.CONTAINS, not: true, result };
    case AFTER:
      return { equivalentFilter: filters.GREATER, not: false, result };
    case BEFORE:
      return { equivalentFilter: filters.LESS, not: false, result };
    case AFTER_OR_EQUAL:
      return { equivalentFilter: filters.GREATER_OR_EQUAL, not: false, result };
    case BEFORE_OR_EQUAL:
      return { equivalentFilter: filters.LESS_OR_EQUAL, not: false, result };
    default:
      return { equivalentFilter: null, not: false, result };
  }
};

const formatFilter = (filter: any, existingWhere = {}) => {
  const { field } = filter;

  const { equivalentFilter, not, result } = getEquivalentFilter(
    filter.operator,
    filter.result,
  );

  if (!equivalentFilter) {
    return existingWhere;
  }

  return set(
    field,
    mergeFilterIntoWhere(existingWhere, equivalentFilter, not, result),
    {},
  );
};

// @ts-expect-error TS(7006): Parameter 'where' implicitly has an 'any' type.
const reduceFilters = (where, filter) => ({
  ...formatFilter(filter, where),
});

const reduceOrFilter = (where: any, dataType: any, orFilter: any) => {
  const mergedOr: any = {
    OR: ensureArray(orFilter.branches)
      .map((branch: any) => formatFilterAndMergeCustomFilters(branch, dataType))
      .filter((filter: any) => Object.keys(filter).length > 0),
  };

  if (mergedOr.OR.length > 0) {
    return { ...where, ...mergedOr };
  }

  return where;
};

export const formatFilterAndMergeCustomFilters = (
  customFilters: any[],
  dataType: any,
  currentWhere = {},
) =>
  ensureArray(customFilters)
    .map((customFilter: any) => formatWhereCondition(customFilter, dataType))
    .filter(filterWhereCondition)
    .reduce((whereAcc: any, filter: any) => {
      if (filter.operator === OR) {
        return reduceOrFilter(whereAcc, dataType, filter);
      }

      const currentWhereForField = get(whereAcc, [filter.field], {});

      const mergedValue = [filter].reduce(reduceFilters, currentWhereForField);

      return { ...whereAcc, ...mergedValue };
    }, currentWhere);

// @ts-expect-error TS(7023): 'reduceQueryObjectToDeps' implicitly has return ty... Remove this comment to see the full error message
export const reduceQueryObjectToDeps = (
  id: any,
  rootPath: any,
  queryObject: any,
) =>
  // @ts-expect-error TS(2769): No overload matches this call.
  Object.entries(queryObject).reduce((acc, [key, value]) => {
    if (value === true) {
      return [
        ...acc,
        // @ts-expect-error TS(2345): Argument of type '{ id: any; path: any; dataType: ... Remove this comment to see the full error message
        new StateItem({
          id: id,
          path: safelyAppendPath(rootPath, key),
          dataType: TEXT,
          source: DATABASE,
        }),
      ];
    }

    return [
      ...acc,
      ...reduceQueryObjectToDeps(id, safelyAppendPath(rootPath, key), value),
    ];
  }, []);

export const appendInitialDepPath = (depPath: any, dep: any) => {
  if (!dep) {
    return depPath;
  }

  const initalDepPath = cleanEdgesNodeFromDepPath(dep.path)
    .split('.')
    .slice(0, -1);

  if (initalDepPath.length === 0) {
    return depPath;
  }

  return `${depPath}${initalDepPath.join('.')}.`;
};

const getFieldsFromActionButton = (actionButton: any) => {
  const { actions = [] } = actionButton;

  return ensureArray(actions)
    .filter((action: any) =>
      isIframeFieldAction(action) ? action.iframe.field : action.field,
    )
    .map((action: any) => ({
      name: isIframeFieldAction(action) ? action.iframe.field : action.field,
    }));
};

export const extractFieldsFromActionButtons = (actionButtons: any) =>
  actionButtons.reduce(
    (fieldsAcc: any, actionButton: any) => [
      ...fieldsAcc,
      ...getFieldsFromActionButton(actionButton),
    ],
    [],
  );

export const generateDepForField = (
  // @ts-expect-error TS(7006): Parameter 'elementId' implicitly has an 'any' type... Remove this comment to see the full error message
  elementId,
  // @ts-expect-error TS(7006): Parameter 'prefix' implicitly has an 'any' type.
  prefix,
  // @ts-expect-error TS(7006): Parameter 'field' implicitly has an 'any' type.
  field,
  // @ts-expect-error TS(7006): Parameter 'pathPrefix' implicitly has an 'any' typ... Remove this comment to see the full error message
  pathPrefix,
  // @ts-expect-error TS(7006): Parameter 'subFieldPath' implicitly has an 'any' t... Remove this comment to see the full error message
  subFieldPath,
) => ({
  id: elementId,
  path: `${prefix}${field.name}${pathPrefix}.${subFieldPath}`,
});

export const getDepsForField = (
  field: DataField,
  dataTypes: DataTypes,
  elementId: string,
  prefix: string = '',
) => {
  if (!field) {
    return [];
  }

  if (field.relationship || field.relatedField) {
    const relatedType = dataTypes.getByName(field.type);
    if (relatedType) {
      const pathPrefix = isMultiField(field) ? '.edges.node' : '';

      if (field.type === FILE) {
        return relatedType.fields
          .filter((relatedField: any) => !relatedField.relationship)
          .map((relatedField: any) =>
            generateDepForField(
              elementId,
              prefix,
              field,
              pathPrefix,
              relatedField.apiName,
            ),
          );
      }

      const { imageField, textFields } = findPreviewFields(
        relatedType.fields,
        relatedType,
      );

      const relatedFieldDeps = [
        'id',
        'uuid',
        ...textFields.map((field) => field.apiName),
      ].map((relatedFieldName) =>
        generateDepForField(
          elementId,
          prefix,
          field,
          pathPrefix,
          relatedFieldName,
        ),
      );

      if (imageField && !isMultiField(imageField)) {
        relatedFieldDeps.push(
          generateDepForField(
            elementId,
            prefix,
            field,
            pathPrefix,
            `${imageField.apiName}.url`,
          ),
        );
      }

      return relatedFieldDeps;
    }
  } else {
    if (isOptionType(field.type) && field.options?.length === 0) {
      return [];
    }

    return [
      {
        id: elementId,
        path: `${prefix}${field.name}`,
        source: DATABASE,
        dataType: field.type,
      },
    ];
  }

  return [];
};

export const getDepsForConditions = (
  conditions: any,
  dataType: any,
  dataTypes: any,
  elementId: any,
  prefix = '',
) =>
  ensureArray(conditions).reduce(
    (acc: any, andCondition: any) =>
      ensureArray(andCondition).reduce((subAcc: any, condition: any) => {
        if (!condition.field || !condition.field.path) {
          return subAcc;
        }

        const splitPath = condition.field.path.split('.');

        const lastFieldInPath = getFieldFromDependency(
          splitPath,
          dataType,
          dataTypes,
        );

        if (lastFieldInPath) {
          const appendPeriod = splitPath.length > 1;
          const fieldPathPrefix = `${initial(splitPath).join('.')}${
            appendPeriod ? '.' : ''
          }`;

          const depsForField = getDepsForField(
            lastFieldInPath.field,
            dataTypes,
            elementId,
            safelyAppendPath(prefix, fieldPathPrefix),
          );

          return [...subAcc, ...depsForField];
        }

        return subAcc;
      }, acc),
    [],
  );

export const getDateForFilter = (
  dateValue: any,
  dateFieldFormat: any,
  timeZone: any,
) => {
  if (typeof dateValue === 'string' && dateValue.includes('T')) {
    if (dateFieldFormat === DATE_FORMAT) {
      // Return only the date portion of the date time
      return dateValue.split('T')[0];
    }

    if (timeZone === 'UTC') {
      // Replace any Timezone information with Z offset timezone
      return dateValue.replace(/(Z|([+-]\d\d:\d\d))$/, 'Z');
    }
  }

  return dateValue;
};

const formatRawDateValueForComparison = (
  dateValue: any,
  timeZone: any,
  dateFieldFormat: any,
) => {
  const dtFromInput = getDateFromValue(dateValue, {
    zone: timeZone,
  });

  if (dateFieldFormat === DATE_FORMAT || timeZone) {
    return dtFromInput
      ? dtToUTCMaintainWallTime(dtFromInput, dateFieldFormat !== DATE_FORMAT)
      : null;
  }

  return dtFromInput ? dtToLocalTZMaintainWallTime(dtFromInput, true) : null;
};

const formatRawValueForComparison = (
  rawValue: any,
  conditionField: any,
  dataType: any,
  project: any,
) => {
  if (!dataType || !rawValue || !conditionField) {
    return rawValue;
  }

  const splitPath = conditionField.path.split('.');
  const fieldDependency = getFieldFromDependency(
    splitPath,
    dataType,
    project.dataTypes,
  );

  if (fieldDependency) {
    const { field } = fieldDependency;

    if (field.type === DATE) {
      const dateFieldFormat = get(field, 'typeOptions.format');
      const timeZone = get(field, 'typeOptions.timeZone');
      const rawDateValue = getDateForFilter(
        rawValue,
        dateFieldFormat,
        timeZone,
      );

      if (conditionField.dataType === ARRAY && Array.isArray(rawDateValue)) {
        return (
          rawDateValue.some((dateValue: any) =>
            formatRawDateValueForComparison(
              dateValue,
              timeZone,
              dateFieldFormat,
            ),
          ) || null
        );
      }

      return formatRawDateValueForComparison(
        rawDateValue,
        timeZone,
        dateFieldFormat,
      );
    }

    if (
      (field.relationship || field.relatedField) &&
      typeof rawValue === 'object' &&
      !Array.isArray(rawValue)
    ) {
      if (isMultiField(field)) {
        return get(rawValue, 'edges', []).map((edge: any) => edge.node.id);
      } else {
        return get(rawValue, 'id');
      }
    }
  }

  return rawValue;
};

const getDataTypeFromScope = (
  scope: Record<string, Record<string, any>>,
  depValue: DepValue | undefined,
  dataTypes: DataTypes,
) => {
  if (!depValue) {
    return undefined;
  }

  const scopeId = depValue.id;

  const dataTypeName = get(scope, [scopeId, '_dataType']);

  if (dataTypeName) {
    return dataTypes.getByName(dataTypeName);
  }

  if (scopeId === AUTH_WRAPPER_ID) {
    return dataTypes.getByName(USER);
  }

  return undefined;
};

export const conditionIsTrue = (
  condition: Partial<Condition>,
  scope: Record<string, Record<string, any>>,
  project: Project,
) => {
  const conditionFieldValue = resolveSingleDataItem(
    { data: condition.field },
    scope,
    project,
    true,
  );

  const conditionValue = resolveDataValue(
    condition.value,
    scope,
    project,
    true,
  );

  if (!condition.field || !condition.operator) {
    // No-op because the condition isn't setup
    return {
      condition,
      resolvedValues: {
        fieldValue: undefined,
        value: undefined,
      },
      result: true,
    };
  }

  const conditionFieldDataType = getDataTypeFromScope(
    scope,
    condition.field,
    project.dataTypes,
  );

  const { field: conditionField } =
    getFieldFromDependency(
      condition.field.path.split('.'),
      conditionFieldDataType,
      project.dataTypes,
    ) ?? {};
  const multiField = conditionField && conditionField.multiple;

  const formattedConditionFieldValue = formatRawValueForComparison(
    conditionFieldValue,
    condition.field,
    conditionFieldDataType,
    project,
  );

  const formattedConditionValue = formatRawValueForComparison(
    conditionValue,
    condition.field,
    conditionFieldDataType,
    project,
  );

  const result = multiField
    ? ensureArray(formattedConditionFieldValue).every((item: any) =>
        compareValues(item, condition.operator!, formattedConditionValue),
      )
    : compareValues(
        formattedConditionFieldValue,
        condition.operator,
        formattedConditionValue,
      );

  return {
    condition,
    resolvedValues: {
      fieldValue: formattedConditionFieldValue,
      value: formattedConditionValue,
    },
    result,
  };
};

export const conditionsAreMet = (
  conditions: any,
  scope: any,
  project: Project,
) => {
  if (!conditions) {
    return true;
  }

  return ensureArray(conditions).some((andConditions: any) =>
    ensureArray(andConditions).every(
      (condition: any) => conditionIsTrue(condition, scope, project).result,
    ),
  );
};

export const failedConditions = (
  andConditions: any,
  scope: any,
  project: Project,
) => {
  if (!andConditions) {
    return [];
  }

  return ensureArray(andConditions)
    .map((condition: any) => {
      const result = conditionIsTrue(condition, scope, project);

      if (result.result) {
        return null;
      } else {
        return result;
      }
    })
    .filter(Boolean);
};

export const staticConditions = (field: DataField): Condition[] => {
  if (field.typeOptions?.format === EMAIL_FORMAT) {
    return [
      {
        field: {
          dataType: TEXT,
          id: RECORD_SCOPE,
          path: field.name,
        },
        operator: EMAIL as Operator,
        result: undefined,
        value: undefined,
      },
    ];
  }

  if (field.typeOptions?.format === URL_FORMAT) {
    return [
      {
        field: {
          dataType: TEXT,
          id: RECORD_SCOPE,
          path: field.name,
        },
        operator: URL as Operator,
        result: undefined,
        value: undefined,
      },
    ];
  }

  if (field.typeOptions?.format === IP_ADDRESS_FORMAT) {
    return [
      {
        field: {
          dataType: TEXT,
          id: RECORD_SCOPE,
          path: field.name,
        },
        operator: IP_ADDRESS as Operator,
        result: undefined,
        value: undefined,
      },
    ];
  }

  if (NUMERIC_DATATYPES.includes(field.type) && !allowNegative(field)) {
    return [
      {
        field: {
          dataType: INTEGER,
          id: RECORD_SCOPE,
          path: field.name,
        },
        operator: GREATER_OR_EQUAL,
        result: undefined,
        value: [{ text: '0' }],
      },
    ];
  }

  if (field.type === TEXT && !isNil(field.typeOptions?.max)) {
    return [
      {
        field: {
          dataType: INTEGER,
          id: RECORD_SCOPE,
          path: `${field.name}.length`,
        },
        operator: LESS_OR_EQUAL,
        result: undefined,
        value: [{ text: `${field.typeOptions?.max}` }],
      },
    ];
  }

  return [];
};

export const formatFullNameObject = (name: string, field: DataField) => {
  const subFields = field.typeOptions?.subFields;

  const hasMiddleNameOption = !!subFields?.middle;
  const hasTitleOption = !!subFields?.title;

  const titles = subFields?.title?.options?.map((option) => option.display) ?? [
    'Mr.',
    'Mrs.',
    'Ms.',
  ];

  let splitName = name.split(' ').filter((segment) => segment !== '');

  const title = titles.includes(splitName[0]) ? splitName.shift() : undefined;
  const last = splitName.length > 1 && splitName.pop();
  const first = hasMiddleNameOption ? splitName.shift() : splitName.join(' ');
  const middle =
    hasMiddleNameOption && splitName.length > 0
      ? splitName.join(' ')
      : undefined;

  return {
    ...(title && hasTitleOption && { title: title }),
    ...(first && { first: first }),
    ...(middle && { middle: middle }),
    ...(last && { last: last }),
  };
};

import gql from 'graphql-tag';
import {
  PROJECT_DATA_TYPES_QUERY_STRING,
  PROJECT_DOCUMENT_QUERY_STRING,
  PROJECT_QUERY_STRING,
  PROJECT_WITHOUT_DATA_TYPES_QUERY_STRING,
  dataTypeFragment,
  fieldFragment,
  permissionRuleFragment,
  projectFragment,
  workflowWithConfigFragment,
} from '@noloco/core/src/queries/core';

const templateFragment = `
  id
  name
  title
  thumbnail
  airtableBaseLink
  previewLink
  templateInfoUrl
  description
  category
  dataTypeSourceType
  creator {
    id
    firstName
    lastName
    profilePicture {
      id
      uri
    } 
  }
  projects {
    id
    name
    dataSourceLink
    dataSourceType
    previewLink
  }
`;

export const PROJECT_QUERY = gql`
  ${PROJECT_QUERY_STRING}
`;

export const PROJECT_DATA_TYPES_QUERY = gql`
  ${PROJECT_DATA_TYPES_QUERY_STRING}
`;

export const PROJECT_WITHOUT_DATA_TYPES_QUERY = gql`
  ${PROJECT_WITHOUT_DATA_TYPES_QUERY_STRING}
`;

export const PROJECT_DOCUMENT_QUERY = gql`
  ${PROJECT_DOCUMENT_QUERY_STRING}
`;

export const BILLING_PLAN_QUERY = gql`
  query billingPlan {
    billingPlan {
      type
      team {
        name
        id
      }
      paymentMethodId
      createdAt
      trialEnd
      periodEnd
      status
      subscriptionChangedAt
      customConfig
      addons {
        externalUsers {
          enabled
          domains
        }
      }
      usage {
        plan {
          activeUsers
          activeWorkflows
          builders
          internalActiveUsers
          externalActiveUsers
          projects
          rollingActiveUsers
          seats
          teamMembers
          workflowRuns
        }
        project {
          externalUsers
          filesGb
          userRoles
        }
      }
    }
  }
`;

const dataTypeHintFragment = `
  hint
`;

export const IS_VALID_PROJECT_NAME_QUERY = gql`
  query isValidProjectName($projectId: String!) {
    isValidProjectName(projectId: $projectId)
  }
`;

export const UPDATE_PROJECT_TEAM = gql`
  mutation updateProjectTeam($projectName: String!, $teamId: Int!, $ownerId: Int) {
    updateProjectTeam(projectName: $projectName, teamId: $teamId, ownerId: $ownerId) {
      ${projectFragment}
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $name: String!
    $path: [String!]!
    $value: JSON
    $id: String
  ) {
    updateProject(name: $name, path: $path, value: $value, id: $id) {
      id
      elements
      settings
      workflows
      hasUnpublishedChanges
    }
  }
`;

export const UPDATE_PROJECT_STATUS = gql`
  mutation updateProjectStatus($name: String!, $live: Boolean!) {
    updateProjectStatus(name: $name, live: $live) {
      ${projectFragment}
    }
  }
`;

export const PUBLISH_PROJECT_BY_VERSION = gql`
    mutation publishProjectByVersion($name: String!, $targetVersion: Int!) {
        publishProjectByVersion(name: $name, targetVersion: $targetVersion) {
            ${projectFragment}
        }
    }
`;

export const PUBLISH_PROJECT = gql`
  mutation publishProject($name: String!, $publishMessage: String) {
    publishProject(name: $name, publishMessage: $publishMessage) {
      id
      name
      publishedVersion
    }
  }
`;

export const REVERT_EDITOR_TO_PROJECT_VERSION = gql`
  mutation revertEditorToProjectVersion($name: String!, $targetVersion: Int!){
    revertEditorToProjectVersion(name: $name, targetVersion: $targetVersion) {
      ${projectFragment}
    }
  }
`;

export const PROJECT_SUBSCRIPTION = gql`
  subscription OnProjectUpdate($projectName: String!) {
    projectUpdated(projectName: $projectName) {
      id
      path
      value
      project {
        id
        name
        elements
        settings
        publishedVersion
        hasUnpublishedChanges
        media {
          id
          name
          fileType
          size
          uri
          mimetype
        }
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject($name: String!, $teamId: Int!) {
    createProject(name: $name, teamId: $teamId) {
      ${projectFragment}
    }
  }
`;

export const CLONE_PROJECT = gql`
  mutation cloneProject($name: String!, $baseName: String!, $dataSourceConnection: JSON, $dataSourceType: DataTypeSourceType, $teamId: Int!, $skipMissingFields: Boolean) {
    cloneProject(name: $name, baseName: $baseName, dataSourceConnection: $dataSourceConnection, dataSourceType: $dataSourceType, teamId: $teamId, skipMissingFields: $skipMissingFields) {
      ${projectFragment}
    }
  }
`;
export const DELETE_PROJECT = gql`
  mutation deleteProject($name: String!) {
    deleteProject(name: $name) {
      id
      name
    }
  }
`;

export const TEMPLATES = gql`
  query templates {
    templates {
      data {
        ${templateFragment}
      }
    }
  }
`;

export const TEMPLATE = gql`
  query template($id: ID!) {
    template(id: $id) {
      ${templateFragment}
    }
  }
`;

export const UPLOAD_PROJECT_MEDIA = gql`
  mutation uploadMedia($projectName: String!, $file: Upload!) {
    uploadMedia(projectName: $projectName, file: $file) {
      id
      uri
      name
      size
      fileType
      mimetype
      encoding
    }
  }
`;

export const ADD_DATA_TYPE = gql`
  mutation addDataType($projectName: String!, $display: String!, $fields: [DataFieldInput!]) {
    addDataType(projectName: $projectName, display: $display, fields: $fields) {
      ${dataTypeFragment}
    }
  }
`;

export const UPDATE_DATA_TYPE = gql`
  mutation updateDataType($projectName: String!, $id: ID!, $primaryField: ID) {
    updateDataType(projectName: $projectName, id: $id, primaryField: $primaryField) {
      ${dataTypeFragment}
    }
  }
`;

export const UPDATE_DATA_TYPE_NAME = gql`
  mutation updateDataTypeName(
    $projectName: String!
    $dataTypeId: Int!
    $display: String!
  ) {
    updateDataTypeName(
      projectName: $projectName
      dataTypeId: $dataTypeId
      display: $display
    ) {
      ${dataTypeFragment}
    }
  }
`;

export const UPDATE_DATA_FIELD_ORDER = gql`
  mutation updateDataFieldOrder($projectName: String!, $dataTypeId: ID!, $order: [DataFieldOrderInput!]!) {
    updateDataFieldOrder(projectName: $projectName, dataTypeId: $dataTypeId, order: $order) {
      ${dataTypeFragment}
    }
  }
`;

export const UPDATE_DATA_FIELD_TYPE = gql`
  mutation updateDataFieldType($projectName: String!, $id: ID!, $type: String!, $typeOptions: JSON) {
    updateDataFieldType(projectName: $projectName, id: $id, type: $type, typeOptions: $typeOptions) {
      ${fieldFragment}
    }
  }
`;

export const ADD_DATA_FIELD = gql`
  mutation addDataField(
    $projectName: String!
    $dataTypeId: Int!
    $display: String!
    $type: String!
    $typeOptions: JSON,
    $unique: Boolean
    $options: [DataFieldOptionInput!]
    $relationship: Relationship
    $reverseDisplay: String
    $autoRelationshipConfig: AutoRelationshipFieldConfigInput
    $rollup: RollupInput
  ) {
    addDataField(
      projectName: $projectName
      dataTypeId: $dataTypeId
      display: $display
      type: $type
      typeOptions: $typeOptions,
      unique: $unique
      options: $options
      relationship: $relationship
      reverseDisplay: $reverseDisplay
      autoRelationshipConfig: $autoRelationshipConfig
      rollup: $rollup
    ) {
      ${fieldFragment}
    }
  }
`;

export const UPSERT_DATA_FIELD = gql`
  mutation upsertDataField(
    $projectName: String!
    $dataTypeId: Int!
    $id: Int!
    $display: String!
    $type: String!
    $typeOptions: JSON,
    $unique: Boolean
    $options: [DataFieldOptionInput!]
    $relationship: Relationship
    $reverseDisplay: String
    $autoRelationshipConfig: AutoRelationshipFieldConfigInput
    $rollup: RollupInput
  ) {
    upsertDataField(
      projectName: $projectName
      dataTypeId: $dataTypeId
      id: $id
      display: $display
      type: $type
      typeOptions: $typeOptions,
      unique: $unique
      options: $options
      relationship: $relationship
      reverseDisplay: $reverseDisplay
      autoRelationshipConfig: $autoRelationshipConfig
      rollup: $rollup
    ) {
      ${fieldFragment}
    }
  }
`;

export const UPDATE_DATA_FIELD = gql`
  mutation updateDataField(
    $projectName: String!
    $id: Int!
    $display: String
    $relationship: Relationship
    $reverseDisplay: String
    $typeOptions: JSON,
    $options: [DataFieldOptionOrderInput!],
  ) {
    updateDataField(
      projectName: $projectName
      id: $id
      display: $display
      relationship: $relationship
      reverseDisplay: $reverseDisplay
      typeOptions: $typeOptions
      options: $options
    ) {
      ${fieldFragment}
    }
  }
`;

export const DELETE_DATA_FIELD = gql`
  mutation deleteDataField(
    $projectName: String!
    $dataTypeId: Int!
    $dataFieldId: Int!
  ) {
    deleteDataField(
      projectName: $projectName
      dataTypeId: $dataTypeId
      dataFieldId: $dataFieldId
    ) {
       ${dataTypeFragment} 
    }
  }
`;

export const TOGGLE_DATA_SOURCE_FIELDS = gql`
  mutation toggleDataSourceFields(
    $projectName: String!
    $dataTypeId: Int!
    $dataFieldIds: [Int!]!
  ) {
      toggleDataSourceFields(
      projectName: $projectName
      dataTypeId: $dataTypeId
      dataFieldIds: $dataFieldIds
    ) {
      ${dataTypeFragment}
    }
  }
`;

export const DELETE_LOOKUP_FIELD = gql`
  mutation deleteDataLookup(
    $projectName: String!
    $dataTypeId: Int!
    $lookupId: Int!
  ) {
    deleteDataLookup(
      projectName: $projectName
      dataTypeId: $dataTypeId
      lookupId: $lookupId
    )
  }
`;

export const DELETE_ROLLUP_FIELD = gql`
  mutation deleteDataRollup(
    $projectName: String!
    $dataTypeId: Int!
    $rollupId: Int!
  ) {
    deleteDataRollup(
      projectName: $projectName
      dataTypeId: $dataTypeId
      rollupId: $rollupId
    )
  }
`;

export const DISCONNECT_DATA_SOURCE = gql`
  mutation disconnectDataSource($projectName: String!, $id: ID!) {
    disconnectDataSource(projectName: $projectName, id: $id)
  }
`;

export const DELETE_DATA_TYPE = gql`
  mutation deleteDataType($projectName: String!, $dataTypeId: Int!) {
    deleteDataType(projectName: $projectName, dataTypeId: $dataTypeId)
  }
`;

export const DISABLE_DATA_TYPE = gql`
  mutation disableDataType($projectName: String!, $dataTypeId: Int!) {
    disableDataType(projectName: $projectName, dataTypeId: $dataTypeId)
  }
`;

export const ENABLE_DATA_TYPES = gql`
  mutation enableDataTypes($projectName: String!, $dataTypeIds: [Int!]!) {
    enableDataTypes(projectName: $projectName, dataTypeIds: $dataTypeIds)
  }
`;

export const ADD_DATA_FIELD_OPTION = gql`
  mutation createDataFieldOption($projectName: String!, $dataTypeId: Int!, $dataFieldId: Int!, $option: DataFieldOptionInput!) {
    createDataFieldOption(projectName: $projectName, dataTypeId: $dataTypeId, dataFieldId: $dataFieldId, option: $option) {
      ${fieldFragment}
    }
  }
`;

export const REMOVE_DATA_FIELD_OPTION = gql`
  mutation removeDataFieldOption($projectName: String!, $dataTypeId: Int!, $dataFieldId: Int!, $optionId: Int!) {
    removeDataFieldOption(projectName: $projectName, dataTypeId: $dataTypeId, dataFieldId: $dataFieldId, optionId: $optionId) {
      ${fieldFragment}
    }
  }
`;

export const RENAME_DATA_FIELD_OPTION = gql`
  mutation renameDataFieldOption($projectName: String!, $dataTypeId: Int!, $dataFieldId: Int!, $optionId: Int!, $option: DataFieldOptionInput!) {
    renameDataFieldOption(projectName: $projectName, dataTypeId: $dataTypeId, dataFieldId: $dataFieldId, optionId: $optionId, option: $option) {
      ${fieldFragment}
    }
  }
`;

export const ADD_DATA_LOOKUP = gql`
    mutation addDataLookup(
        $projectName: String!
        $dataTypeId: Int!
        $display: String!
        $sourceFieldId: ID!
        $valueFieldId: ID!
    ) {
        addDataLookup(
            projectName: $projectName
            dataTypeId: $dataTypeId
            display: $display
            sourceFieldId: $sourceFieldId
            valueFieldId: $valueFieldId
        ) {
            ${fieldFragment}
        }
    }
`;

export const CONNECT_DATA_SOURCE = gql`
  mutation connectDataSource(
    $projectName: String!
    $display: String!
    $connection: JSON!
    $type: DataTypeSourceType!
  ) {
    connectDataSource(
      projectName: $projectName
      display: $display
      connection: $connection
      type: $type
    ) {
      id
      name
      display
      externalId
      connection
      type
      hasSynced
      types {
        ${dataTypeFragment}
      }
    }
  }
`;

export const DISABLE_DATA_SOURCE_TYPES = gql`
  mutation disableDataSourceTypes(
    $id: ID!
    $projectName: String!
    $dataTypeIds: [ID!]!
    $type: DataTypeSourceType!
  ) {
    disableDataSourceTypes(
      projectName: $projectName
      id: $id
      dataTypeIds: $dataTypeIds
      type: $type
    ) {
      id
      name
      display
      connection
      type
      hasSynced
      types {
        ${dataTypeFragment}
      }
    }
  }
`;

export const UPDATE_DATA_SOURCE = gql`
  mutation updateDataSource(
    $id: ID!
    $projectName: String!
    $externalId: String!
    $display: String!
    $connection: JSON!
    $type: DataTypeSourceType!
  ) {
    updateDataSource(
      id: $id
      projectName: $projectName
      externalId: $externalId
      display: $display
      connection: $connection
      type: $type
    ) {
      id
      name
      display
      externalId
      connection
      type
      hasSynced
      types {
        ${dataTypeFragment}
      }
    }
  }
`;

export const GET_DATA_SOURCE = gql`
  query dataSource($projectId: String!, $id: ID!) {
    dataSource(projectId: $projectId, id: $id) {
      id
      name
      display
      externalId
      connection
      hasSynced
    }
  }
`;

export const GET_FULL_DATA_SOURCE = gql`
  query dataSource($projectId: String!, $id: ID!) {
    dataSource(projectId: $projectId, id: $id) {
      id
      name
      display
      externalId
      connection
      hasSynced
      types {
        ${dataTypeFragment}
      }
    }
  }
`;

export const GET_DATA_SOURCES = gql`
  query dataSources($projectId: String!) {
    dataSources(projectId: $projectId) {
      id
      name
      display
      externalId
      connection
      hasSynced
      type
      types {
        ${dataTypeFragment}
      }
    }
  }
`;

export const SYNC_INFORMATION_QUERY = gql`
  query syncInformation(
    $projectName: String!
    $dataTypeId: ID!
    $lastFailures: Int
  ) {
    syncInformation(
      projectName: $projectName
      dataTypeId: $dataTypeId
      lastFailures: $lastFailures
    ) {
      dataSyncLastFailedAt
      dataSyncLastFailures {
        error
        failedAt
        failure
        id
      }
      dataSyncLastSucceededAt
      fieldsLastChangedAt
      lastRefreshedAt
      status
    }
  }
`;

export const DATA_SOURCE_FIELDS_QUERY = gql`
  query dataSourceFields($projectName: String!, $dataTypeId: ID!) {
    dataSourceFields(projectName: $projectName, dataTypeId: $dataTypeId) {
      ${fieldFragment}
      enabled
    }
  }
`;

export const SYNC_DATA_SOURCE_SCHEMA = gql`
  mutation syncDataSourceSchema($projectName: String!, $id: ID!) {
    syncDataSourceSchema(projectName: $projectName, id: $id)
  }
`;

export const SYNC_DATA_SOURCE_DATA = gql`
  mutation syncDataSourceData(
    $projectName: String!
    $id: ID!
    $isFirstSync: Boolean
  ) {
    syncDataSourceData(
      projectName: $projectName
      id: $id
      isFirstSync: $isFirstSync
    )
  }
`;

export const SYNC_SCHEMA_AND_DATA = gql`
  mutation syncSchemaAndData($projectName: String!, $dataTypeId: ID!) {
    syncSchemaAndData(projectName: $projectName, dataTypeId: $dataTypeId)
  }
`;

const USER_LIST_FRAGMENT = `
  id
  name
  display
  dataType {
    ${dataTypeFragment}
  }
  emailField {
    ${fieldFragment}
  }
  firstNameField {
    ${fieldFragment}
  }
  lastNameField {
    ${fieldFragment}
  }
  companyField {
    ${fieldFragment}
  }
  profilePictureField {
    ${fieldFragment}
  }
  paymentsIdField {
    ${fieldFragment}
  }
  fileSharingIdField {
    ${fieldFragment}
  }
  defaultRoleId
  source {
    type
    collectionType
    name
    display
    id
  }
  dataSource {
    name
    display
    type
    externalId
    hasSynced
  }
  conditionalInclude
  conditionalRoles {
    id
    roleId
    conditions
  }
`;

export const GET_DATA_TYPE = gql`
  query dataType($projectId: String!, $id: ID!) {
    dataType(projectId: $projectId, id: $id) {
      ${dataTypeFragment}
    }
  }
`;

export const GET_DISABLED_DATA_TYPES = gql`
  query disabledDataTypes($projectId: String!, $dataSourceId: ID!) {
    disabledDataTypes(projectId: $projectId, dataSourceId: $dataSourceId) {
      id
      display
    }
  }
`;

export const GET_USER_LISTS = gql`
  query userLists($projectId: String!) {
    userLists(projectId: $projectId) {
      ${USER_LIST_FRAGMENT}
    }
  }
`;

export const ADD_USER_LIST = gql`
  mutation addUserList($projectName: String!, $userList: UserListInput!) {
    addUserList(projectName: $projectName, userList: $userList) {
      ${USER_LIST_FRAGMENT}
    }
  }
`;

export const UPDATE_USER_LIST = gql`
  mutation updateUserList($projectName: String!, $userList: UserListInput!) {
    updateUserList(projectName: $projectName, userList: $userList) {
      ${USER_LIST_FRAGMENT}
    }
  }
`;

export const DELETE_USER_LIST = gql`
  mutation deleteUserList($projectName: String!, $id: ID!, $dataTypeId: ID!) {
    deleteUserList(projectName: $projectName, id: $id, dataTypeId: $dataTypeId)
  }
`;

const AIRTABLE_BASES_FRAGMENT = `
  id
  name
  permissionLevel
  connected
`;

export const GET_AIRTABLE_BASES = gql`
  query airtableBases($projectId: String) {
    airtableBases(projectId: $projectId) {
      ${AIRTABLE_BASES_FRAGMENT}
    }
  }
`;

export const GET_HUBSPOT_CUSTOM_OBJECTS = gql`
  query hubspotCustomObjects($projectId: String) {
    hubspotCustomObjects(projectId: $projectId) {
      id
      label
      name
      connected
    }
  }
`;

export const GET_SMART_SUITE_WORKSPACES = gql`
  query smartSuiteWorkspaces($projectId: String) {
    smartSuiteWorkspaces(projectId: $projectId) {
      id
      name
      solutions {
        id
        name
        connected
      }
    }
  }
`;

export const GET_XANO_WORKSPACES = gql`
  query xanoWorkspaces(
    $authToken: String
    $dataSourceId: ID
    $projectId: String
  ) {
    xanoWorkspaces(
      xanoAuthToken: $authToken
      dataSourceId: $dataSourceId
      projectId: $projectId
    ) {
      id
      name
      domain
      customDomain
      instanceName
      instanceDisplay
      description
      connected
    }
  }
`;

export const GET_SLACK_CHANNELS = gql`
  query slackChannels($projectId: String) {
    slackChannels(projectId: $projectId) {
      id
      name
    }
  }
`;

const SAML_PROVIDER_FRAGMENT = `
  id
  emailDomains
  entryPoint
  issuer
  cert
  emailAttribute
  firstNameAttribute
  lastNameAttribute
  defaultRoleId
`;

export const DELETE_SAML_PROVIDER = gql`
  mutation deleteSamlProvider($projectName: String!, $id: ID!) {
    deleteSamlProvider(projectName: $projectName, id: $id)
  }
`;

export const GET_SAML_PROVIDERS = gql`
  query samlProviders($projectId: String!) {
    samlProviders(projectId: $projectId) {
      ${SAML_PROVIDER_FRAGMENT}
    }
  }
`;

export const UPSERT_SAML_PROVIDER = gql`
  mutation upsertSamlProvider($projectName: String!, $id: ID, $config: SamlProviderInput!) {
    upsertSamlProvider(projectName: $projectName, id: $id, config: $config) {
      ${SAML_PROVIDER_FRAGMENT}
    }
  }
`;

export const TOGGLE_DATA_TYPE_PERMISSIONS_ENABLED = gql`
 mutation togglePermissionsEnabled($projectName: String!, $dataTypeId: Int!) {
  togglePermissionsEnabled(
      projectName: $projectName
      dataTypeId: $dataTypeId
  ) {
    ${dataTypeFragment}
  }
 }
`;

export const ADD_DATA_TYPE_PERMISSION_RULE = gql`
 mutation addPermissionRule($projectName: String!, $permissionRule: PermissionRuleInput!) {
  addPermissionRule(
      projectName: $projectName
      permissionRule: $permissionRule
  ) {
    ${permissionRuleFragment}
  }
 }
`;

export const CLONE_DATA_TYPE_PERMISSION_RULE = gql`
  mutation clonePermissionRule(
    $projectName: String!
    $dataTypeId: Int!
    $id: Int!
  ) {
    clonePermissionRule(
      projectName: $projectName
      dataTypeId: $dataTypeId
      id: $id
    ) {
      ${permissionRuleFragment}
    }
  }
`;

export const DELETE_DATA_TYPE_PERMISSION_RULE = gql`
  mutation deletePermissionRule(
    $projectName: String!
    $dataTypeId: Int!
    $id: Int!
  ) {
    deletePermissionRule(
      projectName: $projectName
      dataTypeId: $dataTypeId
      id: $id
    )
  }
`;

export const UPDATE_DATA_TYPE_PERMISSION_RULE = gql`
 mutation updatePermissionRule($projectName: String!, $permissionRule: PermissionRuleInput!) {
  updatePermissionRule(
      projectName: $projectName
      permissionRule: $permissionRule
  ) {
    ${permissionRuleFragment}
  }
 }
`;

export const ADD_DATA_WORKFLOW = gql`
  mutation addDataWorkflow(
    $projectName: String!
    $dataTypeId: Int!
    $name: String!
    $trigger: ActionType!
  ) {
    addDataWorkflow(
      projectName: $projectName
      dataTypeId: $dataTypeId
      name: $name
      trigger: $trigger
    ) {
      ${workflowWithConfigFragment}
    }
  }
`;

export const UPDATE_DATA_WORKFLOW = gql`
    mutation updateDataWorkflow(
        $projectName: String!
        $workflowId: ID!
        $name: String!
        $description: String
        $trigger: ActionType!
        $enabled: Boolean
    ) {
        updateDataWorkflow(
            projectName: $projectName
            workflowId: $workflowId
            name: $name
            description: $description
            trigger: $trigger
            enabled: $enabled
        ) {
            ${workflowWithConfigFragment}
        }
    }
`;

export const CLONE_DATA_WORKFLOW = gql`
    mutation cloneDataWorkflow(
        $projectName: String!
        $workflowId: ID!
    ) {
        cloneDataWorkflow(
            projectName: $projectName
            workflowId: $workflowId
        ) {
            ${workflowWithConfigFragment}
        }
    }
`;

export const REMOVE_DATA_WORKFLOW = gql`
  mutation removeDataWorkflow($projectName: String!, $workflowId: ID!) {
    removeDataWorkflow(projectName: $projectName, workflowId: $workflowId)
  }
`;

export const TEST_DATA_SOURCE_ENDPOINT = gql`
  mutation testDataSourceEndpoint(
    $projectName: String!
    $dataSourceId: ID!
    $api: ApiInput!
    $endpoint: ApiEndpointInput!
  ) {
    testDataSourceEndpoint(
      projectName: $projectName
      dataSourceId: $dataSourceId
      api: $api
      endpoint: $endpoint
    ) {
      status
      data
      error
    }
  }
`;

export const CONNECT_STRIPE = gql`
  mutation connectStripe($projectName: String!) {
    connectStripe(projectName: $projectName) {
      account {
        id
        businessType
        businessProfile {
          mcc
          name
          productDescription
          supportEmail
        }
        chargesEnabled
        country
        defaultCurrency
        payoutsEnabled
      }
      url
    }
  }
`;

export const CONNECT_GOOGLE_SIGN_IN = gql`
  mutation connectGoogleSignIn($projectName: String!, $clientId: String!) {
    connectGoogleSignIn(projectName: $projectName, clientId: $clientId) {
      signIn {
        id
        clientId
      }
    }
  }
`;

export const ADD_DOMAIN = gql`
  mutation addDomain($projectName: String!, $name: String!) {
    addDomain(projectName: $projectName, name: $name) {
      id
      name
      active
    }
  }
`;

export const REMOVE_DOMAIN = gql`
  mutation removeDomain($projectName: String!, $id: ID!) {
    removeDomain(projectName: $projectName, id: $id) {
      id
      name
    }
  }
`;

export const VERIFY_DOMAIN = gql`
  query verifyDomain($projectName: String!, $id: ID!) {
    verifyDomain(projectName: $projectName, id: $id) {
      id
      name
      active
    }
  }
`;

export const CREATE_PUBLIC_FORM = gql`
    mutation createPublicForm(
        $projectName: String!
        $formName: String!
        $dataTypeId: ID!
        $elementId: String!
        $onSubmitMessage: String!
    ) {
        createPublicForm(
            projectName: $projectName
            formName: $formName
            dataTypeId: $dataTypeId
            elementId: $elementId
            onSubmitMessage: $onSubmitMessage
        ) {
            id
            dataType {
                ${dataTypeFragment}
            }
            elementId
            referenceId
            createdAt
            creator {
                id
                email
                firstName
                lastName
            }
        }
    }
`;

export const DELETE_PUBLIC_FORM = gql`
  mutation deletePublicForm(
    $projectName: String!
    $publicFormReferenceId: String!
    $elementId: String!
    $dataTypeId: ID!
  ) {
    deletePublicForm(
      projectName: $projectName
      publicFormReferenceId: $publicFormReferenceId
      elementId: $elementId
      dataTypeId: $dataTypeId
    )
  }
`;

export const UPDATE_SMTP_CONFIG = gql`
  mutation updateSmtpConfig($projectName: String!, $config: SmtpConfigInput!) {
    updateSmtpConfig(projectName: $projectName, config: $config) {
      id
      from
      host
      port
      secure
      username
      password
    }
  }
`;

export const CUSTOM_QUERY = gql`
  query customQuery($projectName: String!, $dataTypeId: ID!) {
    customQuery(projectName: $projectName, dataTypeId: $dataTypeId) {
      display
      primaryKeyName
      query
    }
  }
`;

export const TEST_DATA_SOURCE_CUSTOM_QUERY = gql`
  query testDataSourceCustomQuery(
    $projectName: String!
    $dataSourceId: ID!
    $query: String!
  ) {
    testDataSourceCustomQuery(
      projectName: $projectName
      dataSourceId: $dataSourceId
      query: $query
    ) {
      sample
      schema {
        apiName
        display
        name
        options {
          color
          display
          name
          order
        }
        type
        typeOptions
      }
    }
  }
`;

export const PROJECT_VERSION_HISTORY_QUERY = gql`
  query projectVersionHistory($projectName: String!) {
    projectVersionHistory(projectName: $projectName) {
      publishedBy {
        firstName
        lastName
        email
      }
      publishedAt
      publishMessage
      version
    }
  }
`;

export const RECOMMEND_DATA_TYPES = gql`
  query recommendDataTypes($projectName: String, $prompt: String!) {
    recommendDataTypes(projectName: $projectName, prompt: $prompt) {
      dataTypes {
        description
        display
        name
        fields {
          display
          name
          options {
            color
            display
            name
          }
          relationship
          reverseDisplayName
          reverseName
          type
          typeOptions
          unique
        }
        sample
        source {
          id
          type
        }
      }
      data {
        name
        records
      }
    }
  }
`;

export const SAMPLE_RECOMMEND_DATA_TYPE = gql`
  query sampleRecommendedDataType(
    $recommendedDataType: RecommendedDataTypeInput!
  ) {
    sampleRecommendedDataType(recommendedDataType: $recommendedDataType) {
      sample
    }
  }
`;

export const EDITOR_HINT = gql`
  query editorHint($projectName: String!, $dataTypeId: ID!, $type: DataTypeHintType!) {
    editorHint(projectName: $projectName, dataTypeId: $dataTypeId, type: $type) {
      ${dataTypeHintFragment}
    }
  }
`;

export const FEEDBACK_EDITOR_HINT = gql`
  mutation feedbackEditorHint(
    $projectName: String!
    $dataTypeId: ID!
    $type: DataTypeHintType!
    $feedback: DataTypeHintFeedback!
  ) {
    feedbackEditorHint(
      projectName: $projectName
      dataTypeId: $dataTypeId
      type: $type
      feedback: $feedback
    ) {
      ${dataTypeHintFragment}
    }
  }
`;

export const SUGGEST_LAYOUT = gql`
  query suggestLayout($projectName: String!, $dataTypeId: ID!) {
    suggestLayout(projectName: $projectName, dataTypeId: $dataTypeId) {
      layout
      startDate
      endDate
      latitude
      longitude
      groupBy
    }
  }
`;

export const GENERATE_EDITOR_HINTS = gql`
  mutation generateEditorHints($projectName: String!, $dataTypeId: ID!) {
    generateEditorHints(projectName: $projectName, dataTypeId: $dataTypeId)
  }
`;

export const CREATE_RECOMMENDED_DATA_TYPES = gql`
  mutation createRecommendedDataTypes(
    $projectName: String!
    $recommendedDataTypes: [RecommendedDataTypeInput!]!
  ) {
    createRecommendedDataTypes(
      projectName: $projectName
      recommendedDataTypes: $recommendedDataTypes
    ) {
      ${projectFragment}
    }
  }
`;

export const ADD_DATA_SOURCE_CUSTOM_QUERY = gql`
  mutation addDataSourceCustomQuery(
    $projectName: String!
    $dataSourceId: ID!
    $display: String!
    $primaryKeyName: String!
    $query: String!
  ) {
    addDataSourceCustomQuery(
      projectName: $projectName
      dataSourceId: $dataSourceId
      display: $display
      primaryKeyName: $primaryKeyName
      query: $query
    ) {
      ${dataTypeFragment}
    }
  }
`;

export const UPDATE_DATA_SOURCE_CUSTOM_QUERY = gql`
  mutation updateDataSourceCustomQuery(
    $projectName: String!
    $dataTypeId: ID!
    $display: String!
    $primaryKeyName: String!
    $query: String!
  ) {
    updateDataSourceCustomQuery(
      projectName: $projectName
      dataTypeId: $dataTypeId
      display: $display
      primaryKeyName: $primaryKeyName
      query: $query
    ) {
      ${dataTypeFragment}
    }
  }
`;
export const WORKFLOW_EXECUTIONS_QUERY = gql`
  query workflowExecutions(
    $projectName: String!
    $dataTypeId: ID!
    $workflowId: ID!
    $before: DateTime
    $limit: Int
    $successful: Boolean
    $triggerPrimaryFieldName: String
  ) {
    workflowExecutions(
      projectName: $projectName
      dataTypeId: $dataTypeId
      workflowId: $workflowId
      triggerPrimaryFieldName: $triggerPrimaryFieldName
      before: $before
      limit: $limit
      successful: $successful
    ) {
      edges {
        node {
          id
          invocationId
          projectVersion
          workflowId
          recordId
          record {
            id
            uuid
            display
          }
          startedAt
          completedAt
          successful
          failureReason
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
`;

export const WORKFLOW_STATISTICS_QUERY = gql`
  query workflowStatistics($projectName: String!, $workflowId: ID!) {
    workflowStatistics(projectName: $projectName, workflowId: $workflowId) {
      histogram {
        bucket
        value
      }
      summary {
        failed
        successful
      }
    }
  }
`;

export const UPSERT_API_KEY = gql`
  mutation upsertApiKey(
    $projectName: String!
    $integration: ApiKeyIntegration!
    $apiKey: String!
  ) {
    upsertApiKey(
      projectName: $projectName
      integration: $integration
      apiKey: $apiKey
    ) {
      id
    }
  }
`;

export const GET_TEAM_USAGE = gql`
  query teamUsage($overrides: [TeamUsageOverrideInput!]) {
    teamUsage(overrides: $overrides) {
      plan {
        activeUsers
      }
      projects {
        name
        addons {
          externalUsers
        }
        usage {
          externalUsers
        }
      }
    }
  }
`;

export const docsAutomatorPlaceholdersQuery = gql`
  query docsAutomatorPlaceholders($docId: String!, $projectName: String!) {
    docsAutomatorPlaceholders(docId: $docId, projectName: $projectName) {
      placeholders {
        main
        lineItems
      }
    }
  }
`;

export const docsAutomatorAutomationsQuery = gql`
  query docsAutomatorAutomations($projectName: String!) {
    docsAutomatorAutomations(projectName: $projectName) {
      automations {
        title
        id
      }
    }
  }
`;

export const docsAutomatorValidateApiKey = gql`
  query docsAutomatorValidateApiKey($apiKey: String!) {
    docsAutomatorValidateApiKey(apiKey: $apiKey)
  }
`;

export const UPLOAD_USER_PROFILE_PICTURE = gql`
  mutation uploadProfilePicture($file: Upload!) {
    uploadProfilePicture(file: $file) {
      id
      uri
      name
      size
      fileType
      mimetype
      encoding
    }
  }
`;

export const ADD_PRE_BUILT_DATA_SOURCE_VIEWS = gql`
  mutation addPreBuiltDataSourceViews(
    $projectName: String!
    $dataSourceId: ID!
  ) {
    addPreBuiltDataSourceViews(
      projectName: $projectName
      dataSourceId: $dataSourceId
    )
  }
`;

export const INVITE_APP_USERS = gql`
  mutation inviteUsers($projectName: String!, $userEmails: [String!]!) {
    inviteUsers(projectName: $projectName, userEmails: $userEmails) {
      createdAt
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

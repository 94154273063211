export const CHANGE_USER_POPOVER_OPENED = 'view as user popover opened';
export const CHANGE_USER_SELECTED = 'view as user selected';
export const CHOSE_DATA_SOURCE = 'chose data source';
export const LOGO_CLICKED = 'logo clicked';
export const PUBLISH_CLICKED = 'publish button clicked';
export const NEW_ELEMENT_CLICKED = 'new element clicked';
export const ELEMENT_ADDED = 'element added';
export const NEW_PAGE_SAVED = 'new page saved';
export const NEW_DATA_SOURCE_CLICKED = 'new data source clicked';
export const NEW_DATA_TYPE_SAVED = 'new data type saved';

export const ONBOARDING_CHECKLIST_OPENED = 'onboard checklist opened';
export const ONBOARDING_CHECKLIST_CLOSED = 'onboard checklist closed';
export const ONBOARDING_CHECKLIST_COMPLETED = 'onboard checklist completed';
export const ONBOARDING_CHECKLIST_CTA_CLICKED = 'onboard checklist cta clicked';
export const NEW_DATA_FIELD_CLICKED = 'new data field clicked';
export const NEW_DATA_FIELD_SAVED = 'new data field saved';
export const DATA_FIELD_UPDATED = 'data field updated';

export const NEW_LOOKUP_FIELD_SAVED = 'new lookup field saved';
export const NEW_ROLLUP_FIELD_SAVED = 'new rollup field saved';
export const NEW_DATA_WORKFLOW_CLICKED = 'new data workflow clicked';
export const CLONE_DATA_WORKFLOW_CLICKED = 'clone data workflow clicked';
export const ACTION_ADDED = 'action added';
export const ACTION_DELETED = 'action deleted';
export const NEW_PROJECT_WIZARD_NEXT_CLICKED =
  'new project wizard next clicked';
export const NEW_PROJECT_WIZARD_BACK_CLICKED =
  'new project wizard back clicked';
export const PLAYGROUND_TOUR_CLOSED = 'playground tour closed';
export const PLAYGROUND_TOUR_STEP_VIEWED = 'playground tour step viewed';
export const SESSION_START = 'SESSION_START';
export const USER_ROLE_ADDED = 'user role added';
export const USER_ROLE_UPDATED = 'user role updated';
export const GUIDE_LINK_CLICKED = 'guide link clicked';
export const WORKFLOW_TEST_MENU_OPENED = 'workflow test menu opened';
export const WORKFLOW_TEST_BUTTON_CLICKED = 'workflow test button clicked';

// Onboarding related events
export const PORTAL_NEW_APP = 'New app button in portals landing page clicked';
export const CREATE_NEW_APP = 'Create new app button in templates step clicked';
export const PROJECT_CREATED = 'Project created';
export const BACK_BUTTON = 'Onboarding back button clicked';
export const CLOSE_ONBOARDING_DATA_SOURCE = 'Onboarding closed';
export const BRING_TO_APP = 'Onboarding completed';
export const DATA_SOURCE_SELECTED = 'Data source selected';
export const PROJECT_NAME_SELECTED = 'Unique project name selected';
export const PREVIEW_TEMPLATE = 'Templates preview link clicked';
export const USE_TEMPLATE = 'Use template clicked';
export const VIEW_AIRTABLE_BASE = 'Templates airtable source link clicked';
export const VIEW_TEMPLATE_INFO = 'Templates info link clicked';
export const CONFIGURE_TEMPLATE_SUBMIT = 'Submit button clicked';
export const SELECTED_COLLECTIONS = 'Changed onboarding collection';
export const CHANGED_VIEWS = 'Changed onboarding collection view';
export const ONBOARDING_MODAL_CLOSED = 'Closed onboarding modal';
export const RECOMMENDED_DATA_TYPE_ADD_FIELD =
  'Added a field to a recommended data type';
export const RECOMMENDED_DATA_TYPE_CHANGE_FIELD =
  'Changed a field on a recommended data type';
export const RECOMMENDED_DATA_TYPE_RENAME = 'Renamed a recommended data type';

// Example Project Events
export const WEBSITE_EXAMPLES = {
  LAYOUT_CHANGED: 'example layout changed',
  COLLECTION_FIELD_TOGGLED: 'example collection field toggled',
  COLLECTION_LABEL_CHANGED: 'example collection label changed',
  FORM_FIELD_TOGGLED: 'example form field toggled',
  FORM_LABEL_CHANGED: 'example form label changed',
  FORM_REQUIRED_TOGGLED: 'example form required toggled',
  RECORD_SECTION_TOGGLED: 'example record section toggled',
  ACTION_BUTTON_CLICKED: 'example action button clicked',
  ACTION_BUTTON_CONFIRMED: 'example action button confirmed',
  ACTION_BUTTON_CANCELED: 'example action button canceled',
  WORKFLOW_STARTED: 'example workflow start clicked',
};

// Version History
export const PREVIOUS_VERSION_PUBLISHED = 'Published previous version';
export const REVERTED_EDITOR_TO_PREVIOUS_VERSION = 'Published previous version';

const ONBOARDING_EVENTS = [
  PORTAL_NEW_APP,
  CREATE_NEW_APP,
  PROJECT_CREATED,
  BACK_BUTTON,
  CLOSE_ONBOARDING_DATA_SOURCE,
  BRING_TO_APP,
  DATA_SOURCE_SELECTED,
  PROJECT_NAME_SELECTED,
  PREVIEW_TEMPLATE,
  USE_TEMPLATE,
  VIEW_AIRTABLE_BASE,
  VIEW_TEMPLATE_INFO,
  CONFIGURE_TEMPLATE_SUBMIT,
  SELECTED_COLLECTIONS,
  CHANGED_VIEWS,
];

const EXAMPLE_PROJECT_EVENTS = Object.values(WEBSITE_EXAMPLES);

export const DARK_MODE_TOGGLED_BY_USER = 'user toggled dark mode';
export const DARK_MODE_TOGGLED_FOR_PROJECT = 'project admin toggled dark mode';

const ANALYTICS_DEBUG_ENABLED =
  typeof localStorage !== 'undefined' &&
  localStorage.getItem('noloco.analytics.debug') === 'true';

const categories = {
  BUILDER: 'builder',
  ONBOARDING: 'onboarding',
  MARKETING_WEBSITE: 'marketing-website',
};

const debugEvent = (event: any, eventObject = {}) => {
  if (ANALYTICS_DEBUG_ENABLED) {
    console.group('Track:', event);
    if ((eventObject as any).category) {
      console.log('Category:', (eventObject as any).category);
    }
    Object.entries(eventObject).forEach(([key, val]) => {
      console.log(`${key}:`, val);
    });

    console.groupEnd();
  }
};

export const trackPage = (
  category: string,
  pathname: string,
  properties?: Record<string, any>,
) => {
  window.analytics.page(category, pathname, properties);
  debugEvent('Page View', { category, pathname, ...properties });
};

export const analyticsIdentity = (properties: any) => {
  window.analytics.identify(properties.userId, properties);
  debugEvent('Identify', properties);
};

const getCategory = (event: string) => {
  if (ONBOARDING_EVENTS.includes(event)) {
    return categories.ONBOARDING;
  }

  if (EXAMPLE_PROJECT_EVENTS.includes(event)) {
    return categories.MARKETING_WEBSITE;
  }

  return categories.BUILDER;
};

export const trackEvent = (
  event: any,
  label?: any,
  value?: any,
  otherProperties = {},
) => {
  const eventObject = {
    category: getCategory(event),
    label,
    value,
    ...(label ? { [label]: value } : {}),
    ...otherProperties,
  };

  window.analytics.track(event, eventObject);
  debugEvent(event, eventObject);
};

export const trackElementAdded = (type: any, name: any) => {
  trackEvent(ELEMENT_ADDED, 'type', type, { name });
};

export const trackPlaygroundTourClosed = (step: any) => {
  trackEvent(PLAYGROUND_TOUR_CLOSED, 'step', step);
};

export const trackPlaygroundTourStepViewed = (step: any) => {
  trackEvent(PLAYGROUND_TOUR_STEP_VIEWED, 'step', step);
};

export const trackNewProjectWizardNextClicked = (step: any) => {
  trackEvent(NEW_PROJECT_WIZARD_NEXT_CLICKED, 'step', step);
};

export const trackNewProjectWizardBackClicked = (step: any) => {
  trackEvent(NEW_PROJECT_WIZARD_BACK_CLICKED, 'step', step);
};

export const trackNewDataSourceClicked = (source: any) => {
  trackEvent(NEW_DATA_SOURCE_CLICKED, 'source', source);
};

export const trackChoseDataSource = (source: any) => {
  trackEvent(CHOSE_DATA_SOURCE, 'source', source);
};

export const trackOnboardingChecklistCtaClicked = (taskId: any) => {
  trackEvent(ONBOARDING_CHECKLIST_CTA_CLICKED, 'task', taskId);
};

export const trackGuideLinkClick = (url: string) => {
  trackEvent(GUIDE_LINK_CLICKED, 'guide', new URL(url).pathname);
};

export const trackWorkflowTestMenuOpened = (
  workflowId: number,
  referenceId: string,
) => {
  trackEvent(WORKFLOW_TEST_MENU_OPENED, 'workflowId', workflowId, {
    referenceId,
  });
};

export const trackWorkflowTestButtonClicked = (
  workflowId: number,
  referenceId: string,
) => {
  trackEvent(WORKFLOW_TEST_BUTTON_CLICKED, 'workflowId', workflowId, {
    referenceId,
  });
};

import { DateTime } from 'luxon';
import {
  AccountPlan,
  BUSINESS,
  BUSINESS_V2,
  ENTERPRISE,
  EXPERT,
  FREE,
  PRO,
  PROFESSIONAL,
  PRO_V2,
  SCALE,
  STARTER,
  STARTER_V2,
  TEAM,
} from './accountPlans';
import {
  AIRTABLE,
  GOOGLE_SHEETS,
  MYSQL,
  POSTGRES,
  SMART_SUITE,
  SourceType,
  XANO,
} from './dataSources';

/**
 * Features can be on/off, limited to some number/duration or unlimited.
 */
export type FeatureValue = boolean | number | undefined | SourceType;

export interface PlanConfig {
  // Apps
  projects?: number;
  testProjects?: number;
  projectVersions: boolean;

  // Users
  builders?: number;
  teamMembers?: number;
  activeUsers?: number;
  internalActiveUsers?: number;
  externalActiveUsers?: number;
  seats?: number;
  externalUsers?: number | boolean;

  // Data
  records?: number;
  syncedRecords?: number;
  sourceSyncing: boolean;
  prioritySourcesSyncing: boolean;
  filesGb?: number;
  dataSources?: SourceType[];

  // User Access
  userRoles?: number;
  conditionalUserListRoles: boolean;
  fieldPermissions: boolean;
  customVisibilityRules: boolean;
  customFieldVisibility: boolean;

  // Other Features
  fieldValidationRules: boolean;
  premiumLayouts: boolean;
  formSaveActions: boolean;
  scanBarcode: boolean;

  // Collaboration
  commentNotes?: boolean;

  // Automation
  bulkActions?: boolean;
  bulkEdit?: boolean;

  // Branding
  customDomain: boolean;
  customLogin: boolean;
  whiteLabel: boolean;
  smtp: boolean;

  // Workflows
  workflowRuns?: number;
  activeWorkflows?: number;
  premiumActions: boolean;

  // Technical Features
  api: boolean;
  sdk: boolean;
  customCode: boolean;

  // Security Features
  sso: boolean;
  disablePasswordLogin: boolean;
  ['2fa']: boolean;

  // Support
  dataConsultation: boolean;
  chat: boolean;
  privateSlack: boolean;
  accountManager: boolean;

  // Internal use
  workflowEmailsMinute: number;
  workflowEmailsHour: number;
  workflowEmailsRecipientsCap: number;
}

export type DeprecatedFeature = string;

export type Feature = keyof PlanConfig;
export type PlanConfigs = { [_T in AccountPlan]?: PlanConfig };

// Apps
export const LIVE_APPS: Feature = 'projects';
export const TEST_APPS: Feature = 'testProjects';
export const APP_VERSIONS: Feature = 'projectVersions';

// Users
export const BUILDERS: Feature = 'builders';
export const TEAM_MEMBERS: Feature = 'teamMembers';
export const ACTIVE_USERS: Feature = 'activeUsers';
export const ACTIVE_INTERNAL_USERS: Feature = 'internalActiveUsers';
export const ACTIVE_EXTERNAL_USERS: Feature = 'externalActiveUsers';
export const EXTERNAL_USERS: Feature = 'externalUsers';

// Data
export const DATABASE_RECORDS: Feature = 'records';
export const SYNCED_RECORDS: Feature = 'syncedRecords';
export const AUTOMATIC_SOURCE_SYNCING: Feature = 'sourceSyncing';
export const PRIORITY_SOURCE_SYNCING: Feature = 'prioritySourcesSyncing';
export const FILE_STORAGE: Feature = 'filesGb';
export const DATA_SOURCES: Feature = 'dataSources';

// User Access
export const USER_ROLES: Feature = 'userRoles';
export const CONDITIONAL_USER_LIST_ROLES = 'conditionalUserListRoles';
export const FIELD_LEVEL_PERMISSIONS: Feature = 'fieldPermissions';
export const CUSTOM_VISIBILITY_RULES: Feature = 'customVisibilityRules';
export const CONDITIONAL_FIELD_VISIBILITY: Feature = 'customFieldVisibility';

// Other Features
export const PREMIUM_LAYOUTS: Feature = 'premiumLayouts';
export const FIELD_VALIDATION_RULES: Feature = 'fieldValidationRules';
export const FORM_SAVE_ACTIONS: Feature = 'formSaveActions';
export const BARCODE_SCAN: Feature = 'scanBarcode';

// Collaboration
export const COMMENT_NOTES: Feature = 'commentNotes';

// Automation
export const BULK_ACTIONS: Feature = 'bulkActions';
export const BULK_EDIT: Feature = 'bulkEdit';

// Branding
export const CUSTOM_DOMAIN: Feature = 'customDomain';
export const CUSTOM_LOGIN: Feature = 'customLogin';
export const WHITE_LABEL: Feature = 'whiteLabel';
export const SMTP: Feature = 'smtp';

// Workflows
export const WORKFLOW_RUNS: Feature = 'workflowRuns';
export const ACTIVE_WORKFLOWS: Feature = 'activeWorkflows';
export const PREMIUM_ACTIONS: Feature = 'premiumActions';

// Technical Features
export const API_ACCESS: Feature = 'api';
export const PYTHON_SDK: Feature = 'sdk';
export const CUSTOM_CODE: Feature = 'customCode';

// Security Features
export const SINGLE_SIGN_ON: Feature = 'sso';
export const DISABLE_PASSWORD_LOGIN: Feature = 'disablePasswordLogin';
export const TWO_FACTOR_AUTHENTICATION: Feature = '2fa';

// Support
export const DATA_CONSULTATION: Feature = 'dataConsultation';
export const LIVE_CHAT: Feature = 'chat';
export const PRIVATE_SLACK_CHANNEL: Feature = 'privateSlack';
export const ACCOUNT_MANAGER: Feature = 'accountManager';

// Internal use
export const WORKFLOW_EMAILS_MINUTE: Feature = 'workflowEmailsMinute';
export const WORKFLOW_EMAILS_HOUR: Feature = 'workflowEmailsHour';
export const WORKFLOW_EMAILS_RECIPIENTS_CAP: Feature =
  'workflowEmailsRecipientsCap';

const ACTIVE_WORKFLOW_EMAILS = {
  [WORKFLOW_EMAILS_MINUTE]: 50,
  [WORKFLOW_EMAILS_HOUR]: 100,
  [WORKFLOW_EMAILS_RECIPIENTS_CAP]: 20,
};

export const FREE_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: 0,
  [TEST_APPS]: 0,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: 3,
  [EXTERNAL_USERS]: false,

  // Data
  [AUTOMATIC_SOURCE_SYNCING]: false,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: 1,

  // User Access
  [USER_ROLES]: 2,
  [CONDITIONAL_USER_LIST_ROLES]: false,
  [FIELD_LEVEL_PERMISSIONS]: false,
  [CUSTOM_VISIBILITY_RULES]: false,
  [CONDITIONAL_FIELD_VISIBILITY]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: false,
  [PREMIUM_LAYOUTS]: false,
  [FORM_SAVE_ACTIONS]: false,
  [BARCODE_SCAN]: false,

  // Branding
  [CUSTOM_DOMAIN]: false,
  [CUSTOM_LOGIN]: false,
  [WHITE_LABEL]: false,
  [SMTP]: false,

  // Workflows
  [WORKFLOW_RUNS]: 500,
  [ACTIVE_WORKFLOWS]: undefined,
  [PREMIUM_ACTIONS]: false,

  // Technical Features
  [API_ACCESS]: false,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: false,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: false,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,

  // Internal use
  [WORKFLOW_EMAILS_MINUTE]: 5,
  [WORKFLOW_EMAILS_HOUR]: 50,
  [WORKFLOW_EMAILS_RECIPIENTS_CAP]: 10,
};

const withFreePlan = (planConfigs: PlanConfigs): PlanConfigs => ({
  ...planConfigs,
  [FREE]: FREE_PLAN,
});

/**
 * The ENTERPRISE plan is not truly unlimited, most of the usage limits will be
 * set to some custom value in TeamPlan.customConfig, however for completeness
 * they are defaulted to unlimited here.
 */
export const ENTERPRISE_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: true,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: false,

  // Data
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: true,
  [FILE_STORAGE]: undefined,
  [DATA_SOURCES]: undefined,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: true,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,
  [FORM_SAVE_ACTIONS]: true,
  [BARCODE_SCAN]: true,

  // Collaboration
  [COMMENT_NOTES]: true,

  // Automation
  [BULK_ACTIONS]: true,
  [BULK_EDIT]: true,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [CUSTOM_LOGIN]: true,
  [WHITE_LABEL]: true,
  [SMTP]: true,

  // Workflows
  [WORKFLOW_RUNS]: undefined,
  [ACTIVE_WORKFLOWS]: undefined,
  [PREMIUM_ACTIONS]: true,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: true,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: true,
  [DISABLE_PASSWORD_LOGIN]: true,
  [TWO_FACTOR_AUTHENTICATION]: true,

  // Support
  [DATA_CONSULTATION]: true,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: true,
  [ACCOUNT_MANAGER]: true,

  // Internal use
  ...ACTIVE_WORKFLOW_EMAILS,
  [WORKFLOW_EMAILS_RECIPIENTS_CAP]: 30,
};

/**
 * The EXPERT plan is not an advertised plan, instead when experts want to work
 * with Noloco for clients we give them ENTERPRISE access without priority
 * source syncing.
 */
export const EXPERT_PLAN = {
  ...ENTERPRISE_PLAN,
  [LIVE_APPS]: 0,
  [PRIORITY_SOURCE_SYNCING]: false,
};

const withCustomPlans = (planConfigs: PlanConfigs): PlanConfigs => ({
  ...planConfigs,
  [ENTERPRISE]: ENTERPRISE_PLAN,
  [EXPERT]: EXPERT_PLAN,
});

// Legacy plans.

export const STARTER_LEGACY_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: false,

  // Data
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: undefined,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: false,
  [FIELD_LEVEL_PERMISSIONS]: false,
  [CUSTOM_VISIBILITY_RULES]: false,
  [CONDITIONAL_FIELD_VISIBILITY]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: false,
  [PREMIUM_LAYOUTS]: false,
  [FORM_SAVE_ACTIONS]: false,
  [BARCODE_SCAN]: false,

  // Branding
  [CUSTOM_DOMAIN]: false,
  [CUSTOM_LOGIN]: false,
  [WHITE_LABEL]: false,
  [SMTP]: false,

  // Workflows
  [WORKFLOW_RUNS]: undefined,
  [ACTIVE_WORKFLOWS]: undefined,
  [PREMIUM_ACTIONS]: false,

  // Technical Features
  [API_ACCESS]: false,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: false,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,

  // Internal use
  ...ACTIVE_WORKFLOW_EMAILS,
};

export const PROFESSIONAL_LEGACY_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: false,

  // Data
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: undefined,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: false,
  [FIELD_LEVEL_PERMISSIONS]: false,
  [CUSTOM_VISIBILITY_RULES]: false,
  [CONDITIONAL_FIELD_VISIBILITY]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: false,
  [PREMIUM_LAYOUTS]: false,
  [FORM_SAVE_ACTIONS]: false,
  [BARCODE_SCAN]: false,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [CUSTOM_LOGIN]: false,
  [WHITE_LABEL]: true,
  [SMTP]: false,

  // Workflows
  [WORKFLOW_RUNS]: undefined,
  [ACTIVE_WORKFLOWS]: undefined,
  [PREMIUM_ACTIONS]: false,

  // Technical Features
  [API_ACCESS]: false,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: false,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,

  // Internal use
  ...ACTIVE_WORKFLOW_EMAILS,
};

export const TEAM_LEGACY_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: false,

  // Data
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: undefined,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: true,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,
  [FORM_SAVE_ACTIONS]: true,
  [BARCODE_SCAN]: false,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [CUSTOM_LOGIN]: false,
  [WHITE_LABEL]: true,
  [SMTP]: false,

  // Workflows
  [WORKFLOW_RUNS]: undefined,
  [ACTIVE_WORKFLOWS]: undefined,
  [PREMIUM_ACTIONS]: false,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,

  // Internal use
  ...ACTIVE_WORKFLOW_EMAILS,
};

export const LEGACY_PLAN_CONFIG: PlanConfigs = withCustomPlans({
  [STARTER]: STARTER_LEGACY_PLAN,
  [PROFESSIONAL]: PROFESSIONAL_LEGACY_PLAN,
  [TEAM]: TEAM_LEGACY_PLAN,
});

// Usage-based plans introduced in September 2022.

export const TEAM_USAGE_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: 1,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: 2,
  [TEAM_MEMBERS]: 10,
  [ACTIVE_USERS]: 100,
  [EXTERNAL_USERS]: false,

  // Data
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: 10,

  // User Access
  [USER_ROLES]: 6,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: true,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [CUSTOM_LOGIN]: false,
  [WHITE_LABEL]: true,
  [SMTP]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,
  [FORM_SAVE_ACTIONS]: true,
  [BARCODE_SCAN]: false,

  // Workflows
  [WORKFLOW_RUNS]: 1000,
  [ACTIVE_WORKFLOWS]: 10,
  [PREMIUM_ACTIONS]: false,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,

  // Internal use
  ...ACTIVE_WORKFLOW_EMAILS,
};

export const SCALE_USAGE_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: 1,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: true,

  // Users
  [BUILDERS]: 3,
  [TEAM_MEMBERS]: 20,
  [ACTIVE_USERS]: 350,
  [EXTERNAL_USERS]: false,

  // Data
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: true,
  [FILE_STORAGE]: 20,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: true,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,
  [FORM_SAVE_ACTIONS]: true,
  [BARCODE_SCAN]: false,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [CUSTOM_LOGIN]: false,
  [WHITE_LABEL]: true,
  [SMTP]: true,

  // Workflows
  [WORKFLOW_RUNS]: 5000,
  [ACTIVE_WORKFLOWS]: 30,
  [PREMIUM_ACTIONS]: false,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: true,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: true,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,

  // Internal use
  ...ACTIVE_WORKFLOW_EMAILS,
};

export const USAGE_BASED_PLAN_CONFIG: PlanConfigs = withFreePlan(
  withCustomPlans({
    [TEAM]: TEAM_USAGE_PLAN,
    [SCALE]: SCALE_USAGE_PLAN,
  }),
);

export const USAGE_BASED_PLAN_EPOCH: DateTime = DateTime.fromISO('2022-09-01', {
  zone: 'utc',
});

// Seat-based plans introduced in November 2022.

export const PRO_SEAT_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: true,

  // Data
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: 10,

  // User Access
  [USER_ROLES]: 6,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: false,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Branding
  [CUSTOM_DOMAIN]: false,
  [CUSTOM_LOGIN]: false,
  [WHITE_LABEL]: false,
  [SMTP]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,
  [FORM_SAVE_ACTIONS]: false,
  [BARCODE_SCAN]: false,

  // Workflows
  [WORKFLOW_RUNS]: 3000,
  [ACTIVE_WORKFLOWS]: undefined,
  [PREMIUM_ACTIONS]: false,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,

  // Internal use
  ...ACTIVE_WORKFLOW_EMAILS,
};

export const BUSINESS_SEAT_PLAN: PlanConfig = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: true,

  // Users
  [BUILDERS]: undefined,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: undefined,
  [EXTERNAL_USERS]: true,

  // Data
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: true,
  [FILE_STORAGE]: 20,

  // User Access
  [USER_ROLES]: undefined,
  [CONDITIONAL_USER_LIST_ROLES]: true,
  [FIELD_LEVEL_PERMISSIONS]: true,
  [CUSTOM_VISIBILITY_RULES]: true,
  [CONDITIONAL_FIELD_VISIBILITY]: true,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [CUSTOM_LOGIN]: true,
  [WHITE_LABEL]: true,
  [SMTP]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: true,
  [PREMIUM_LAYOUTS]: true,
  [FORM_SAVE_ACTIONS]: true,
  [BARCODE_SCAN]: false,

  // Workflows
  [WORKFLOW_RUNS]: 10000,
  [ACTIVE_WORKFLOWS]: undefined,
  [PREMIUM_ACTIONS]: true,

  // Technical Features
  [API_ACCESS]: true,
  [PYTHON_SDK]: true,
  [CUSTOM_CODE]: true,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,

  // Internal use
  ...ACTIVE_WORKFLOW_EMAILS,
};

export const STARTER_V2_PLAN = {
  // Apps
  [LIVE_APPS]: undefined,
  [TEST_APPS]: undefined,
  [APP_VERSIONS]: false,

  // Users
  [BUILDERS]: 1,
  [TEAM_MEMBERS]: undefined,
  [ACTIVE_USERS]: 10,
  [ACTIVE_INTERNAL_USERS]: 4,
  [ACTIVE_EXTERNAL_USERS]: 10,

  // Data
  [DATABASE_RECORDS]: 5_000,
  [SYNCED_RECORDS]: 2_500,
  [AUTOMATIC_SOURCE_SYNCING]: true,
  [PRIORITY_SOURCE_SYNCING]: false,
  [FILE_STORAGE]: 5,
  [DATA_SOURCES]: [AIRTABLE, SMART_SUITE, GOOGLE_SHEETS, XANO],

  // User Access
  [USER_ROLES]: 2,
  [CONDITIONAL_USER_LIST_ROLES]: false,
  [FIELD_LEVEL_PERMISSIONS]: false,
  [CUSTOM_VISIBILITY_RULES]: false,
  [CONDITIONAL_FIELD_VISIBILITY]: false,

  // Branding
  [CUSTOM_DOMAIN]: false,
  [CUSTOM_LOGIN]: false,
  [WHITE_LABEL]: false,
  [SMTP]: false,

  // Other Features
  [FIELD_VALIDATION_RULES]: false,
  [PREMIUM_LAYOUTS]: false,
  [FORM_SAVE_ACTIONS]: false,
  [BARCODE_SCAN]: false,

  // Collaboration
  [COMMENT_NOTES]: false,

  // Automation
  [BULK_ACTIONS]: false,
  [BULK_EDIT]: false,

  // Workflows
  [WORKFLOW_RUNS]: 1000,
  [ACTIVE_WORKFLOWS]: undefined,
  [PREMIUM_ACTIONS]: false,

  // Technical Features
  [API_ACCESS]: false,
  [PYTHON_SDK]: false,
  [CUSTOM_CODE]: false,

  // Security Features
  [SINGLE_SIGN_ON]: false,
  [DISABLE_PASSWORD_LOGIN]: false,
  [TWO_FACTOR_AUTHENTICATION]: false,

  // Support
  [DATA_CONSULTATION]: false,
  [LIVE_CHAT]: true,
  [PRIVATE_SLACK_CHANNEL]: false,
  [ACCOUNT_MANAGER]: false,

  // Internal use
  ...ACTIVE_WORKFLOW_EMAILS,
};

export const PRO_V2_PLAN = {
  ...PRO_SEAT_PLAN,

  // Branding
  [CUSTOM_DOMAIN]: true,
  [CUSTOM_LOGIN]: true,
  [WHITE_LABEL]: true,
  [SMTP]: false,

  // Users
  [BUILDERS]: 1,
  [ACTIVE_USERS]: 50,
  [ACTIVE_INTERNAL_USERS]: 10,
  [ACTIVE_EXTERNAL_USERS]: 50,

  // User access
  [FIELD_LEVEL_PERMISSIONS]: true,

  // Other Features
  [FORM_SAVE_ACTIONS]: true,
  [BARCODE_SCAN]: false,

  // Workflows
  [PREMIUM_ACTIONS]: true,

  // Technical Features
  [API_ACCESS]: false,

  // Data
  [DATABASE_RECORDS]: 50_000,
  [SYNCED_RECORDS]: 25_000,
  [FILE_STORAGE]: 20,
  [DATA_SOURCES]: [AIRTABLE, SMART_SUITE, GOOGLE_SHEETS, XANO],

  // Collaboration
  [COMMENT_NOTES]: true,

  // Automation
  [BULK_ACTIONS]: true,
  [BULK_EDIT]: true,
};

export const BUSINESS_V2_PLAN = {
  ...BUSINESS_SEAT_PLAN,
  // Users
  [BUILDERS]: 3,
  [ACTIVE_USERS]: 100,
  [ACTIVE_INTERNAL_USERS]: 30,
  [ACTIVE_EXTERNAL_USERS]: 100,

  // Other Features
  [BARCODE_SCAN]: true,

  // Data
  [DATABASE_RECORDS]: 150_000,
  [SYNCED_RECORDS]: 50_000,
  [FILE_STORAGE]: 100,
  [DATA_SOURCES]: [AIRTABLE, SMART_SUITE, GOOGLE_SHEETS, XANO, POSTGRES, MYSQL],

  // Collaboration
  [COMMENT_NOTES]: true,

  // Automation
  [BULK_ACTIONS]: true,
  [BULK_EDIT]: true,
};

export const SEAT_BASED_PLAN_CONFIG: PlanConfigs = withFreePlan(
  withCustomPlans({
    [PRO]: PRO_SEAT_PLAN,
    [BUSINESS]: BUSINESS_SEAT_PLAN,
  }),
);

export const V2_PLAN_CONFIG: PlanConfigs = withFreePlan(
  withCustomPlans({
    [STARTER_V2]: STARTER_V2_PLAN,
    [PRO_V2]: PRO_V2_PLAN,
    [BUSINESS_V2]: BUSINESS_V2_PLAN,
  }),
);
export const DEPRECATED_FEATURES: DeprecatedFeature[] = ['records'];

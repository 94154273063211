import { useCallback, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { CopyButton, Modal } from '@noloco/components';
import { LG, MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import { BarcodeScanner } from '@noloco/ui/src/components/BarcodeScanner';
import MarkdownText from '../../../components/MarkdownText';
import {
  ADD_COMMENT,
  COPY,
  CREATE,
  DELETE,
  ON_DEMAND,
  SCAN_BARCODE,
  UPDATE,
  VIEW,
} from '../../../constants/actionTypes';
import { IFRAME } from '../../../constants/elements';
import { DataType } from '../../../models/DataTypes';
import { Action, ActionButton } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { BaseRecord } from '../../../models/Record';
import { clearFieldValues } from '../../../reducers/formFields';
import { getRootFieldDataType } from '../../../utils/fields';
import useActions from '../../../utils/hooks/useActions';
import useActionsWithFormInputs from '../../../utils/hooks/useActionsWithFormInputs';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useOneClickActionMutations from '../../../utils/hooks/useOneClickActionMutations';
import { getText } from '../../../utils/lang';
import ActionButtonForm from './ActionButtonForm';
import AddCommentAction from './AddCommentAction';
import RecordIframe from './RecordIframe';
import ViewActionRecord from './ViewActionRecord';

const ACTIONS_TYPES_WITH_INPUT = [CREATE, UPDATE, IFRAME, SCAN_BARCODE];

const actionRequiresInput = (action: Action) => {
  if (!ACTIONS_TYPES_WITH_INPUT.includes(action.type)) {
    return false;
  }

  return (
    get(action, 'formFields', []).some((formField) => !formField.hidden) ||
    action.type === IFRAME
  );
};

const LANG_KEY = 'elements.VIEW';

type ModalActionButtonProps = {
  action: Action;
  actionButton?: ActionButton;
  actionIndex: number;
  dataType: DataType;
  onCopy: (action: Action, setIsLoading: (isLoading: boolean) => void) => void;
  onDelete: (
    action: Action,
    setIsLoading: (isLoading: boolean) => void,
  ) => void;
  onFinish: (cancelled?: boolean) => void;
  onNavigate: (action: Action) => void;
  onNext: (actionResult?: any) => void;
  onRunWorkflow: (
    action: Action,
    setIsLoading: (isLoading: boolean) => void,
  ) => void;
  project: Project;
  record?: BaseRecord;
  recordScope: Record<string, any>;
  visibilityRulesScope: Record<string, any>;
};

const ModalActionButton = ({
  action,
  actionButton,
  actionIndex,
  dataType,
  onCopy,
  onDelete,
  onFinish,
  onNavigate,
  onNext,
  onRunWorkflow,
  project,
  record,
  recordScope,
}: ModalActionButtonProps) => {
  const dispatch = useDispatch();
  const [lastExecuted, setLastExecuted] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isDarkModeEnabled] = useDarkMode();

  const { submitForm } = useActionsWithFormInputs(action);
  const { onCreate, onUpdate, onScan } = useOneClickActionMutations(
    action,
    dataType,
    project,
    record,
    onNext,
    setIsLoading,
  );

  const nextAction = useMemo(
    () => get(actionButton, ['actions', actionIndex + 1]),
    [actionButton, actionIndex],
  );

  const canExecuteWithoutInput = useMemo(
    () => actionIndex > 0 && (!action || !actionRequiresInput(action)),
    [action, actionIndex],
  );

  const { onExecuteAction } = useActions(
    canExecuteWithoutInput ? onCreate : submitForm,
    onDelete,
    onNavigate,
    onNext,
    onRunWorkflow,
    canExecuteWithoutInput ? onUpdate : submitForm,
    onCopy,
    submitForm,
    onScan,
    onFinish,
  );

  const onConfirm = useCallback(() => {
    setLastExecuted(actionIndex);
    return onExecuteAction(action, setIsLoading, nextAction);
  }, [action, actionIndex, onExecuteAction, nextAction]);

  const shouldContinue = useMemo(
    () => canExecuteWithoutInput && lastExecuted !== actionIndex,
    [actionIndex, canExecuteWithoutInput, lastExecuted],
  );

  const size = useMemo(() => {
    const type = get(action, 'type', null);

    if (type === DELETE) {
      return SM;
    }

    if (type === VIEW) {
      return get(action, 'size', LG);
    }

    if (type === IFRAME) {
      return get(action, 'props.size', MD);
    }

    return MD;
  }, [action]);

  const confirmText = useMemo(() => {
    if (action.type === ADD_COMMENT) {
      return getText('actions.types', ADD_COMMENT, 'send');
    }

    if (action.type === COPY) {
      return getText('elements.COPY.confirm');
    }

    if (action.type === SCAN_BARCODE) {
      return getText(LANG_KEY, 'actionButtons', nextAction ? 'next' : 'done');
    }

    return getText(LANG_KEY, 'actionButtons.confirm');
  }, [action, nextAction]);

  const canConfirm = useMemo(
    () =>
      action.type !== SCAN_BARCODE ||
      (action.type === SCAN_BARCODE && !!action.multiScan),
    [action],
  );

  const rootDataType = useMemo(
    () =>
      action &&
      action.field &&
      getRootFieldDataType(action.field, dataType, project.dataTypes),
    [action, dataType, project.dataTypes],
  );

  const handleOnCancel = useCallback(() => {
    if (action && (action.type === CREATE || action.type === UPDATE)) {
      dispatch(
        clearFieldValues({
          dataTypeName: get(action.field ? rootDataType : dataType, 'name'),
          fieldNames: get(action, 'formFields', []).map(
            (formField) => formField.name,
          ),
          id:
            action.type === CREATE
              ? 'NEW'
              : get(record, action.field ? [action.field, 'id'] : 'id'),
          updateTime: Date.now(),
        }),
      );
    }
    onFinish(true);
  }, [onFinish, dispatch, dataType, record, action, rootDataType]);

  useEffect(() => {
    if (shouldContinue) {
      onConfirm();
    }
  }, [onConfirm, shouldContinue]);

  if (!record) {
    return null;
  }

  return (
    <Modal
      canConfirm={canConfirm}
      closeOnOutsideClick={false}
      confirmDisabled={isLoading || shouldContinue}
      confirmText={confirmText}
      data-testid="action-button-modal"
      loading={isLoading}
      onCancel={handleOnCancel}
      onClose={handleOnCancel}
      onConfirm={onConfirm}
      size={size}
      title={actionButton?.title}
      variant={action.type === DELETE ? 'danger' : 'primary'}
      className={isDarkModeEnabled ? 'dark' : undefined}
    >
      {actionButton?.description && (
        <div className="mb-6 w-full text-base">
          <MarkdownText disabledHeadings={false}>
            {actionButton.description}
          </MarkdownText>
        </div>
      )}
      {action && action.type === COPY && action.copy && (
        <div className="mb-6 flex w-full flex-row items-center justify-start gap-2">
          <span>{action.copy.value}</span>
          <CopyButton
            tooltipContent={getText(LANG_KEY, 'fields.copied')}
            value={action.copy.value}
            size={16}
          />
        </div>
      )}
      {action && action.type === IFRAME && (
        <RecordIframe
          dataType={dataType}
          project={project}
          record={record}
          showBorder={false}
          {...action.iframe}
        />
      )}
      {action && action.type === ADD_COMMENT && (
        <AddCommentAction
          action={action}
          dataType={dataType}
          record={record}
          project={project}
          setIsLoading={setIsLoading}
          onNext={onNext}
        />
      )}
      {action && action.type === VIEW && (
        <ViewActionRecord
          action={action}
          dataType={rootDataType ? rootDataType : dataType}
          project={project}
          record={record}
          recordScope={recordScope}
          size={size}
        />
      )}
      {action && action.type === SCAN_BARCODE && (
        <>
          {action.barcodeField ? (
            <BarcodeScanner
              connectDeviceText={getText(
                LANG_KEY,
                'barcodeScanner.connextDevice',
              )}
              onScan={onScan}
              successText={getText(LANG_KEY, 'barcodeScanner.success')}
              isDarkModeEnabled={isDarkModeEnabled}
            />
          ) : (
            <div className="flex h-32 items-center justify-center">
              {getText(LANG_KEY, 'barcodeScanner.noBarcodeField')}
            </div>
          )}
        </>
      )}
      {action &&
        !canExecuteWithoutInput &&
        ![DELETE, ON_DEMAND, IFRAME, VIEW, ADD_COMMENT].includes(
          action.type,
        ) && (
          <ActionButtonForm
            action={action}
            dataType={dataType}
            onSuccess={onNext}
            onLoadingChange={setIsLoading}
            project={project}
            record={record}
            scope={recordScope}
          />
        )}
    </Modal>
  );
};

export default ModalActionButton;

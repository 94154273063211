import { ID } from '../elements/sections/collections/CollectionEvents';
import { ElementPath } from '../models/Element';
import { Page } from '../utils/pages';
import { FORM, PAGE, RECORD, VIEW } from './elements';

export const MOBILE: EditorView = 'mobile';
export const DESKTOP: EditorView = 'desktop';

export type EditorView = 'mobile' | 'desktop';

export const ItemTypes = {
  FOLDER: 'FOLDER',
  NESTED_ITEM: 'NESTED_ITEM',
  SINGLE_ITEM: 'SINGLE_ITEM',
};

export type Item = {
  childItems?: Item[];
  elementPath: ElementPath;
  id: ID;
  new?: boolean;
  page: Page;
  parentPageId?: ID;
  parentPageType?: string;
  type: string;
};

export const HIDDEN = 'hidden';

export type BuildModeEditors =
  | typeof VIEW
  | typeof PAGE
  | typeof FORM
  | typeof RECORD;

// left editor sections
export type LeftEditorSectionOptions =
  | 'ACTION_BUTTONS'
  | 'CHART'
  | 'COLLECTION'
  | 'DETAILS'
  | 'DIVIDER'
  | 'FILE_GALLERY'
  | 'HIGHLIGHTS'
  | 'IFRAME'
  | 'MARKDOWN'
  | 'QUICK_LINKS'
  | 'STAGES'
  | 'TITLE'
  | 'VIDEO'
  | 'COMMENTS'
  | 'CONTAINER';

export const HIDDEN_PAGES: BuildModeSectionType = 'hidden-pages';
export const LEFT_FORM_SETTINGS: BuildModeSectionType = 'left-form-settings';
export const LEFT_PAGE_SETTINGS: BuildModeSectionType = 'left-page-settings';
export const LEFT_RECORD_SETTINGS: BuildModeSectionType =
  'left-record-settings';
export const LEFT_VIEW_SETTINGS: BuildModeSectionType = 'left-view-settings';
export const MENU_PAGES: BuildModeSectionType = 'menu-pages';

export const LEFT_EDITOR_SECTIONS: BuildModeSectionType[] = [
  HIDDEN_PAGES,
  MENU_PAGES,
];

// right editor sections
export const CUSTOM_RULES: BuildModeSectionType = 'custom-rules';
export const FORM_INPUT_OPTIONS: BuildModeSectionType = 'form-input-options';
export const HEADER: BuildModeSectionType = 'header';
export const DATA_SETTINGS: BuildModeSectionType = 'data-settings';
export const FORM_SAVE_BUTTON: BuildModeSectionType = 'form-save-button';
export const FORM_ON_SAVE_ACTIONS: BuildModeSectionType =
  'form-on-save-actions';
export const LAYOUT_SETTINGS: BuildModeSectionType = 'layout-settings';
export const PUBLIC_FORM: BuildModeSectionType = 'public-form';
export const RECORD_ACTION_BUTTONS: BuildModeSectionType =
  'record-action-buttons';
export const RECORD_COMMENTS: BuildModeSectionType = 'record-comments';
export const RECORD_EDIT_BUTTON: BuildModeSectionType = 'record-edit-button';
export const RECORD_ACTION_BUTTON_ACTIONS: BuildModeSectionType =
  'record-action-button-actions';
export const RECORD_ACTION_BUTTON_TEXT: BuildModeSectionType =
  'record-action-button-text';
export const RECORD_ACTION_BUTTON_VISIBILITY: BuildModeSectionType =
  'record-action-button-visibility';
export const RECORD_ACTION_BUTTON_NOTIFICATIONS: BuildModeSectionType =
  'record-action-button-notifications';
export const RECORD_ACTION_BUTTON_SETTINGS: BuildModeSectionType =
  'record-action-button-settings';
export const RECORD_TABS_VISIBILITY: BuildModeSectionType =
  'record-tabs-visibility';
export const RECORD_TABS: BuildModeSectionType = 'record-tabs';
export const VIEW_ACTIONS: BuildModeSectionType = 'view-actions';
export const VIEW_NAVIGATION: BuildModeSectionType = 'view-navigation';
export const FOLDER_VISIBILITY: BuildModeSectionType = 'folder-visibility';
export const VIEW_EMPTY_STATE: BuildModeSectionType = 'view-empty-state';
export const VIEW_FILTER: BuildModeSectionType = 'view-filter';
export const VIEW_COLOR_CONDITIONS: BuildModeSectionType =
  'view-color-conditions';
export const VIEW_BUTTONS: BuildModeSectionType = 'view-buttons';
export const VIEW_FILTER_FIELDS: BuildModeSectionType = 'view-filter-fields';
export const VIEW_HEADER: BuildModeSectionType = 'view-header';
export const VIEW_SETTINGS: BuildModeSectionType = 'view-settings';
export const VIEW_SETUP: BuildModeSectionType = 'view-setup';
export const VIEW_SORT: BuildModeSectionType = 'view-sort';
export const VIEW_CHARTS_SETTINGS: BuildModeSectionType =
  'view-charts-settings';
export const VIEW_CHARTS_SERIES: BuildModeSectionType = 'view-charts-series';
export const RECORD_QUICK_LINKS: BuildModeSectionType = 'record-quick-links';

// Sections
export const COLLECTION_SECTION: BuildModeSectionType = 'collection-section';
export const TITLE_SECTION: BuildModeSectionType = 'title-section';
export const MEDIA_SECTION: BuildModeSectionType = 'media-section';
export const CHARTS_SECTION: BuildModeSectionType = 'charts-section';
export const NOTICE_SECTION: BuildModeSectionType = 'notice-section';
export const ACTION_BUTTON_SECTION: BuildModeSectionType =
  'action-button-section';
export const COMMONLY_USED: BuildModeSectionType = 'commonly-used';
export const MEDIA: BuildModeSectionType = 'media';

export const RIGHT_EDITOR_SECTIONS: BuildModeSectionType[] = [
  CUSTOM_RULES,
  DATA_SETTINGS,
  LAYOUT_SETTINGS,
  VIEW_ACTIONS,
  VIEW_EMPTY_STATE,
  VIEW_FILTER,
  VIEW_HEADER,
  VIEW_SETTINGS,
  VIEW_SETUP,
  VIEW_SORT,
];

export const EMPTY_COLLECTION = 'EMPTY_COLLECTION';

// editor tabs
export const CHARTS_TAB: BuildModeEditorTabs = 'charts';
export const FIELDS: BuildModeEditorTabs = 'fields';
export const GENERAL: BuildModeEditorTabs = 'general';
export const OPTIONS: BuildModeEditorTabs = 'options';
export const VISIBILITY: BuildModeEditorTabs = 'visibility';
export const VALUES: BuildModeEditorTabs = 'values';

// sidebar item options
export const ADVANCED_OPTIONS: BuildModeSectionType = 'advanced-options';
export const COLLECTIONS_OPTIONS: BuildModeSectionType = 'collections-options';
export const MODULES_OPTIONS: BuildModeSectionType = 'modules-options';

export type BuildModeSectionType =
  | 'advanced-options'
  | 'collections-options'
  | 'collections'
  | 'collections'
  | 'commonly-used'
  | 'commonly-used'
  | 'custom-rules'
  | 'data-settings'
  | 'folder-visibility'
  | 'form-input-options'
  | 'form-on-save-actions'
  | 'form-save-button'
  | 'header'
  | 'hidden-pages'
  | 'layout-settings'
  | 'left-form-settings'
  | 'left-page-settings'
  | 'left-record-settings'
  | 'left-view-settings'
  | 'media'
  | 'media'
  | 'menu-pages'
  | 'modules-options'
  | 'public-form'
  | 'record-action-button-actions'
  | 'record-action-button-actions'
  | 'record-action-button-notifications'
  | 'record-action-button-settings'
  | 'record-action-button-text'
  | 'record-action-button-text'
  | 'record-action-button-visibility'
  | 'record-action-buttons'
  | 'record-comments'
  | 'record-edit-button'
  | 'record-quick-links'
  | 'record-quick-links'
  | 'record-tabs-visibility'
  | 'record-tabs'
  | 'view-actions'
  | 'view-buttons'
  | 'view-charts-series'
  | 'view-charts-settings'
  | 'view-color-conditions'
  | 'view-empty-state'
  | 'view-filter-fields'
  | 'view-filter'
  | 'view-header'
  | 'view-navigation'
  | 'view-settings'
  | 'view-setup'
  | 'view-sort'
  | 'title-section'
  | 'action-button-section'
  | 'media-section'
  | 'charts-section'
  | 'notice-section'
  | 'collection-section';

export type BuildModeEditorTabs =
  | 'charts'
  | 'fields'
  | 'general'
  | 'options'
  | 'values'
  | 'visibility';

export const SIDEBAR_EXPANDED = 'noloco.buildMode.sidebar.expanded';

import { ShirtSize } from '@noloco/components/src/constants/tShirtSizes';
import { Variant } from '@noloco/components/src/constants/variants';
import { ActionButtonExecutionType } from '../constants/actionButtons';
import { ActionType } from '../constants/actionTypes';
import { AuthVisibilityRule } from '../constants/authVisibilityRules';
import { ElementType } from '../constants/elements';
import { FileDisplay } from '../constants/fileDisplay';
import { InternalUserVisibilityRule } from '../constants/internalUsersVisibilityRules';
import { LinkType } from '../constants/linkTypes';
import { MembershipVisibilityRule } from '../constants/membershipVisibilityRules';
import { Operator } from '../constants/operators';
import { RoleVisibilityRule } from '../constants/roleVisibilityRules';
import { WorkflowActionType } from '../constants/workflowActionTypes';
import { IconValue } from './IconValue';

export type DepValue = {
  args?: Record<any, any>;
  dataType?: string;
  display?: string;
  id: string;
  path: string;
  source?: string;
};

export type StringPropSegment = {
  text?: string;
  data?: DepValue;
  static?: boolean;
};

export type StringPropValue = StringPropSegment[];

export type Condition = {
  field: DepValue;
  operator: Operator;
  result: undefined | StringPropValue;
  value?: undefined | StringPropValue;
};

export type VisibilityRules = {
  auth?: AuthVisibilityRule | undefined;
  roleRule?: RoleVisibilityRule | undefined;
  type?: InternalUserVisibilityRule | undefined;
  membership?: MembershipVisibilityRule | undefined;
  roles?: string[];
  plans?: string[];
  customRules?: Condition[][];
};

export type ViewTab = {
  id: string;
  title: string;
  visibilityRules?: VisibilityRules;
};

export type Section = {
  id: string;
  type: string;
  tab?: string;
};

export type FileLayout = {
  display?: FileDisplay;
  size?: ShirtSize;
};

export type Navigate = {
  email: string | undefined;
  page: string[] | undefined;
  pageData: Record<any, any>;
  phone: string | undefined;
  subject: string | undefined;
  url: string | undefined;
  newTab: boolean | undefined;
  type: LinkType;
};

export type BaseAction = {
  id: string;
  type: ActionType | WorkflowActionType;
};

export enum ScanActionType {
  'CREATE' = 'CREATE',
  'UPDATE' = 'UPDATE',
}

export type AddCommentActionConfig = {
  note?: boolean;
  body?: StringPropValue;
};

export type Action = BaseAction & {
  barcodeField?: DepValue;
  copy?: { value: string };
  dataType?: string;
  detailsFields?: any[];
  field?: string;
  fields?: Record<string, DepValue>;
  formFields?: any[];
  iframe?: { src: string };
  multiScan?: boolean;
  navigate?: Navigate;
  redirect?: boolean;
  scanActionType?: ScanActionType;
  size?: ShirtSize;
  type: ActionType;
  workflow?: string;
  addComment?: AddCommentActionConfig;
};

export type NavigateAction = Action & {
  navigate: Navigate;
};

export type ActionButton = {
  id: string;
  appearance?: string;
  execution?: ActionButtonExecutionType;
  buttonText?: string;
  actions: Action[];
  icon?: IconValue;
  title?: string;
  description?: string;
  tooltip?: string;
  visibilityRules?: VisibilityRules;
  notification?: {
    enabled?: boolean;
    text?: string;
    type?: Variant | 'confetti';
  };
};

export type FieldConfigValue = {
  name: string;
};

export type ElementPathSegment = number | string;
export type ElementPath = ElementPathSegment[];

export type Element = {
  actions?: any[];
  children?: Element[];
  container?: string;
  id: string;
  name?: string;
  props: Record<string, any>;
  static?: boolean;
  type: ElementType;
  visibilityRules?: VisibilityRules;
};

import React, { FC, forwardRef, useCallback, useMemo, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import { Button, Tooltip } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import useLocale, {
  getLocaleName,
} from '@noloco/components/src/utils/hooks/useLocale';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { ONE_CLICK } from '../constants/actionButtons';
import {
  BOARD,
  CALENDAR,
  CHARTS,
  COLUMNS,
  CollectionLayout,
  EVENT_BASED_LAYOUTS,
  GANTT,
  MAP,
  PIVOT_TABLE,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '../constants/collectionLayouts';
import {
  DECIMAL,
  INTEGER,
  MULTIPLE_OPTION,
  OBJECT,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { ACTION_BUTTONS } from '../constants/elements';
import { CUSTOM_VISIBILITY_RULES } from '../constants/features';
import { ConditionOperator } from '../constants/operators';
import { OrderByDirection } from '../constants/orderByDirections';
import { PivotTable } from '../constants/pivotTable';
import { DataField } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';
import {
  ActionButton,
  DepValue,
  Element,
  ElementPath,
  VisibilityRules,
} from '../models/Element';
import { PermissionValue } from '../models/Permission';
import { Project } from '../models/Project';
import { BaseRecord, RecordEdge } from '../models/Record';
import {
  CollectionField,
  DataList,
  EditRelatedRecordButtons,
  FilterField,
  GroupByWithField,
  ImportField,
  MapProps,
} from '../models/View';
import { setSelectedElement } from '../reducers/elements';
import { reduceDynamicPropValues } from '../utils/elementPropResolvers';
import { shouldRenderComponent } from '../utils/elementVisibility';
import { canBeCardHeroImage } from '../utils/fields';
import { formatFilterValueForQueryString, paramName } from '../utils/filters';
import { useActionButtons } from '../utils/hooks/useActionButtons';
import { useAddView } from '../utils/hooks/useAddView';
import { useNextBackLink } from '../utils/hooks/useBacklink';
import useCollectionQuery from '../utils/hooks/useCollectionQuery';
import useDarkMode from '../utils/hooks/useDarkMode';
import useIsTable from '../utils/hooks/useIsTable';
import useRemoveRecordFromCollection from '../utils/hooks/useRemoveRecordFromCollection';
import useRouter from '../utils/hooks/useRouter';
import useScopeUser from '../utils/hooks/useScopeUser';
import { getText } from '../utils/lang';
import { FieldConfig } from '../utils/permissions';
import {
  isMultiField,
  isSingleSideOfRelationship,
} from '../utils/relationships';
import { formatQueryString, getViewRoutePrefixForViewId } from '../utils/urls';
import baseElementsConfig from './baseElementConfig';
import { COLLECTION_WRAPPER_STYLES } from './sections/Collection';
import Title from './sections/Title';
import BulkActionsBar from './sections/collections/BulkActionsBar';
import CollectionDataBody from './sections/collections/CollectionDataBody';
import CollectionFilters from './sections/collections/CollectionFilters';
import AddRelatedRecordButton from './sections/view/AddRelatedRecordButton';
import CollectionActionButtons from './sections/view/CollectionActionButtons';
import ExportButton from './sections/view/ExportButton';
import ImportButton from './sections/view/ImportButton';
import MobileActionButtonsWrapper from './sections/view/MobileActionButtonsWrapper';

const buildQueryStringParamValueForFilter = (
  field: DataField,
  newValue: any,
  config: FilterField,
) => {
  const defaultFilterValue = get(config, 'defaultValue');
  const hasDefaultFilter = defaultFilterValue !== undefined;

  const removedDefaultFilterValueFromSingleOptionFieldWithMultiSelect =
    field.type === SINGLE_OPTION && hasDefaultFilter && newValue?.length === 0;

  const removedDefaultFilterValueFromNumberField =
    (field.type === INTEGER || field.type === DECIMAL) &&
    hasDefaultFilter &&
    newValue === undefined;

  if (removedDefaultFilterValueFromNumberField) {
    return null;
  }

  const removedDefaultFilterValueFromTextField =
    field.type === TEXT && hasDefaultFilter && newValue === undefined;

  const removedDefaultFilterValueFromMultiOptionField =
    field.type === MULTIPLE_OPTION &&
    hasDefaultFilter &&
    (newValue?.includes(null) || newValue?.length === 0);

  const removedAllFilterValueFromMultiOptionFieldWithOutDefault =
    field.type === MULTIPLE_OPTION &&
    !hasDefaultFilter &&
    (newValue?.includes(null) || newValue?.length === 0);

  if (removedAllFilterValueFromMultiOptionFieldWithOutDefault) {
    return undefined;
  }

  const removedDefaultFilterFromField =
    field.type !== TEXT && hasDefaultFilter && newValue === null;

  const removeDefaultFilterFromMultiField =
    isMultiField(field) &&
    hasDefaultFilter &&
    (newValue?.edges?.includes(null) || newValue?.edges?.length === 0);

  const removeDefaultFilterFromSingleLinkedFieldWithMultipleSelect =
    config.multiple &&
    isSingleSideOfRelationship(field) &&
    hasDefaultFilter &&
    (newValue?.edges?.includes(null) || newValue?.edges?.length === 0);

  if (
    removeDefaultFilterFromSingleLinkedFieldWithMultipleSelect ||
    removeDefaultFilterFromMultiField ||
    removedDefaultFilterValueFromTextField ||
    removedDefaultFilterValueFromMultiOptionField ||
    removedDefaultFilterFromField ||
    removedDefaultFilterValueFromSingleOptionFieldWithMultiSelect
  ) {
    newValue = null;
  } else if (!hasDefaultFilter && newValue === null) {
    newValue = undefined;
  }

  if (
    (!!field.relationship || !!field.relatedField) &&
    hasDefaultFilter &&
    newValue === null
  ) {
    return null;
  }

  return newValue === null &&
    !removeDefaultFilterFromSingleLinkedFieldWithMultipleSelect &&
    !removeDefaultFilterFromMultiField &&
    !removedDefaultFilterValueFromTextField &&
    !removedDefaultFilterFromField &&
    !removedDefaultFilterValueFromMultiOptionField &&
    !removedDefaultFilterValueFromSingleOptionFieldWithMultiSelect
    ? undefined
    : formatFilterValueForQueryString(field, newValue, config);
};

type ViewCollectionBodyProps = {
  actionButtons: ActionButton[];
  additionalDeps: DepValue[];
  after?: string;
  before?: string;
  calendarDate: string;
  calendarView: string;
  canGroup: boolean;
  charts: any[];
  className: string;
  combinedFilters: {
    operator: ConditionOperator;
    result: any;
    field: string;
  }[];
  coverPhoto: { src: string };
  dataList: DataList;
  dataType: DataType;
  dateEnd: DepValue | undefined;
  dateEndField: DataField;
  dateStart: DepValue | undefined;
  dateStartField: DataField;
  isFilteredByUser: boolean;
  editorMode: boolean;
  editRelatedRecordButtons: EditRelatedRecordButtons;
  element: Element;
  elementPath: ElementPath;
  EmptyState: FC;
  enableDragAndDropEdit: boolean;
  endTime: number;
  exportButtonText?: string;
  exportButtonVisiblityRules?: VisibilityRules;
  importButtonVisibilityRules?: VisibilityRules;
  fieldConfigs: FieldConfig<CollectionField>[];
  filterValues: Record<string, any>;
  formatRecordScope: (record: BaseRecord) => Record<string, any>;
  ganttDependency?: DepValue;
  groupByFields: GroupByWithField[];
  groupOptions: { [key: string]: { hidden: boolean } };
  hideEmptyGroups: boolean;
  hideIfEmpty?: boolean;
  hideNewButton: boolean;
  importButtonText?: string;
  importFields?: ImportField[];
  innerClassName?: string;
  isRecordView?: boolean;
  layout: CollectionLayout;
  limitPerGroup: number;
  map?: MapProps;
  mapLatField?: DataField | null;
  mapLngField?: DataField | null;
  newButton: { visibilityRules?: VisibilityRules };
  newButtonText: string;
  newLink: string | null;
  openFormInModal?: boolean;
  newRecordValues?: Record<string, any>;
  nextBackLink: any[] | null;
  onClick?: () => void;
  permissions: PermissionValue;
  pivotTable?: PivotTable;
  project: Project;
  qsDirection?: OrderByDirection;
  qsOrderBy?: string;
  qsSuffix: string;
  record?: BaseRecord;
  recordActionButtons: ActionButton[];
  recordTitle: string;
  rootDataType?: DataType;
  rowLink: string;
  scope: Record<string, any>;
  search: { enabled?: boolean; placeholder?: string };
  searchEnabled?: boolean;
  showExportButton?: boolean;
  showImportButton?: boolean;
  startTime: number;
  subtitle: string;
  title: string;
  validFilters: { config: FilterField; field: DataField }[];
  viewId: string;
  viewRootPathname: string | null;
  wrapperClassName?: string;
};

const ViewCollectionBody = forwardRef(
  (
    {
      actionButtons,
      additionalDeps,
      after,
      before,
      calendarDate,
      calendarView,
      canGroup,
      charts,
      className,
      combinedFilters,
      isFilteredByUser,
      coverPhoto,
      dataList,
      dataType,
      dateEnd,
      dateEndField,
      dateStart,
      dateStartField,
      editorMode,
      editRelatedRecordButtons,
      element,
      elementPath,
      EmptyState,
      enableDragAndDropEdit,
      endTime,
      exportButtonText,
      fieldConfigs,
      filterValues,
      formatRecordScope,
      ganttDependency,
      groupByFields,
      groupOptions,
      hideEmptyGroups,
      hideIfEmpty = false,
      hideNewButton,
      importButtonText,
      importFields,
      innerClassName,
      isRecordView,
      layout,
      limitPerGroup,
      map,
      mapLatField,
      mapLngField,
      newButton,
      newButtonText,
      newLink,
      openFormInModal,
      newRecordValues,
      nextBackLink,
      onClick,
      permissions,
      pivotTable,
      project,
      qsDirection,
      qsOrderBy,
      qsSuffix,
      record,
      recordActionButtons,
      recordTitle,
      rootDataType,
      rowLink,
      scope,
      search,
      searchEnabled,
      showExportButton,
      showImportButton,
      startTime,
      subtitle,
      title,
      validFilters,
      viewId,
      viewRootPathname,
      wrapperClassName,
      exportButtonVisiblityRules,
      importButtonVisibilityRules,
    }: ViewCollectionBodyProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { sm: isSmScreen } = useBreakpoints();
    const { query: queryParams, pushQueryParams } = useRouter();
    const currentUser = useScopeUser();
    const [isDarkModeEnabled] = useDarkMode();
    const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
    const locale = useLocale();
    const isTable = useIsTable(layout);

    const newButtonVisible = useMemo(
      () =>
        !hideNewButton &&
        (editorMode ||
          shouldRenderComponent(
            currentUser,
            newButton.visibilityRules ?? {},
            project,
            scope,
            customRulesEnabled,
          )),
      [
        currentUser,
        customRulesEnabled,
        editorMode,
        hideNewButton,
        scope,
        newButton.visibilityRules,
        project,
      ],
    );

    const isExportButtonVisible = useMemo(
      () =>
        showExportButton &&
        shouldRenderComponent(
          currentUser,
          exportButtonVisiblityRules ?? {},
          project,
          scope,
          customRulesEnabled,
        ),
      [
        showExportButton,
        currentUser,
        exportButtonVisiblityRules,
        project,
        scope,
        customRulesEnabled,
      ],
    );

    const isImportButtonVisible = useMemo(
      () =>
        showImportButton &&
        shouldRenderComponent(
          currentUser,
          importButtonVisibilityRules ?? {},
          project,
          scope,
          customRulesEnabled,
        ),
      [
        showImportButton,
        currentUser,
        importButtonVisibilityRules,
        project,
        scope,
        customRulesEnabled,
      ],
    );

    const differentNewLink = !!newLink && newLink !== viewId && newLink !== '#';

    const nextNewLinkBackLink = useNextBackLink(viewId, differentNewLink);

    const newButtonLink = useMemo(() => {
      if (newLink === '#') {
        return '#';
      }

      const newFormPath = openFormInModal ? 'new-modal' : 'new';

      if (newLink === undefined) {
        if (!viewRootPathname) {
          return '#';
        }

        return `${viewRootPathname}/${newFormPath}?${queryString.stringify(
          newRecordValues ?? {},
        )}`;
      }

      const customRootPathName = getViewRoutePrefixForViewId(newLink, project);
      if (!customRootPathName) {
        return null;
      }

      return `${customRootPathName}/${newFormPath}?${queryString.stringify({
        ...newRecordValues,
        _parentPage: nextNewLinkBackLink,
      })}`;
    }, [
      newLink,
      newRecordValues,
      nextNewLinkBackLink,
      project,
      viewRootPathname,
      openFormInModal,
    ]);

    const removeRecordFromCollection = useRemoveRecordFromCollection(
      element.id,
    );
    const [selectedRows, setSelectedRows] = useState<BaseRecord[]>([]);
    const { activeActionItem, queueAction } = useActionButtons();

    const dispatch = useDispatch();
    const onAddView = useAddView(project);

    const onAddMissingView = useCallback(() => {
      if (dataType) {
        onAddView(dataType);
        dispatch(setSelectedElement([]));
      }
    }, [dataType, onAddView, dispatch]);

    const showEditRelatedRecordButton = useMemo(
      () =>
        editRelatedRecordButtons.show &&
        !editRelatedRecordButtons.hideAddRelatedRecordButton &&
        permissions.update &&
        !!get(dataList, ['filter', 'path']),
      [
        dataList,
        editRelatedRecordButtons.hideAddRelatedRecordButton,
        editRelatedRecordButtons.show,
        permissions.update,
      ],
    );

    const orderBy = useMemo(() => {
      if (!qsDirection && !qsOrderBy) {
        return get(dataList, 'orderBy');
      }

      return {
        field: qsOrderBy,
        direction: qsDirection,
      };
    }, [dataList, qsDirection, qsOrderBy]);

    const handleSetOrderBy = useCallback(
      (field: any, direction: any) => {
        const fieldName =
          field.type === OBJECT ? `${field.apiName}._root` : field.apiName;

        if (orderBy) {
          if (orderBy.field === fieldName && orderBy.direction === direction) {
            return pushQueryParams({
              [`_orderBy${qsSuffix}`]: undefined,
              [`_direction${qsSuffix}`]: undefined,
            });
          }
        }

        pushQueryParams({
          [`_orderBy${qsSuffix}`]: fieldName,
          [`_direction${qsSuffix}`]: direction,
        });
      },
      [orderBy, pushQueryParams, qsSuffix],
    );

    const setPaginationQuery = useCallback(
      (paginationQuery: any) => {
        pushQueryParams({
          [`_after${qsSuffix}`]: paginationQuery.after,
          [`_before${qsSuffix}`]: paginationQuery.before,
        });
      },
      [pushQueryParams, qsSuffix],
    );

    const maxStickyColumnIndex = useMemo(
      () => (isTable && get(first(fieldConfigs), 'config.sticky') ? 0 : null),
      [fieldConfigs, isTable],
    );
    const showCardHeroImage = useMemo(() => {
      const firstField = first(fieldConfigs);
      return (
        firstField &&
        canBeCardHeroImage(0, firstField.field, layout) &&
        get(firstField.config, 'heroImage')
      );
    }, [fieldConfigs, layout]);

    const transformRecordScope = useCallback(
      (currentScope: any, record: any) => ({
        ...currentScope,
        [`${element.id}:RECORD`]: record,
        [`${element.id}:VIEW`]: record,
        [element.id]: { edges: { node: record } },
      }),
      [element.id],
    );

    const updateFilterValue = useCallback(
      (field, newValue, config = null) => {
        const parent = config.parent
          ? dataType.fields.getByName(config.parent)
          : undefined;

        const fieldName = paramName(field, parent);

        pushQueryParams({
          [`${fieldName}${qsSuffix}`]: buildQueryStringParamValueForFilter(
            field,
            newValue,
            config,
          ),
          [`_after${qsSuffix}`]: undefined,
          [`_before${qsSuffix}`]: undefined,
        });
      },
      [dataType, pushQueryParams, qsSuffix],
    );

    const showTableSummary = useMemo(
      () =>
        (isTable &&
          fieldConfigs.some(({ config }: any) => !!config.groupSummary)) ||
        (layout === PIVOT_TABLE &&
          get(pivotTable, 'showTableAggregation', true)),
      [fieldConfigs, isTable, layout, pivotTable],
    );

    const recordQueryString = useMemo(() => {
      let qs = null;
      if (layout === SPLIT) {
        qs = queryString.stringify(
          validFilters.reduce(
            (qaAcc, { field }) => {
              const fieldKey = `${field.apiName}${qsSuffix}`;
              const filterValue = queryParams[fieldKey];
              if (filterValue !== undefined) {
                return {
                  ...qaAcc,
                  [fieldKey]: filterValue,
                };
              }

              return qaAcc;
            },
            {
              [`_after${qsSuffix}`]: after,
              [`_before${qsSuffix}`]: before,
              [`_orderBy${qsSuffix}`]: qsOrderBy,
              [`_direction${qsSuffix}`]: qsDirection,
              _parentPage: nextBackLink,
            },
          ),
        );
      } else {
        if (nextBackLink) {
          qs = queryString.stringify({ _parentPage: nextBackLink });
        }
      }

      return formatQueryString(qs);
    }, [
      after,
      before,
      layout,
      nextBackLink,
      qsDirection,
      qsOrderBy,
      qsSuffix,
      queryParams,
      validFilters,
    ]);

    const layoutUsesAllRecords = useMemo(
      () => [...EVENT_BASED_LAYOUTS, CHARTS, PIVOT_TABLE].includes(layout),
      [layout],
    );

    const {
      edges,
      rawData,
      pageInfo,
      totalCount,
      nodeQueryObject,
      loading,
      error,
      refetch,
      parentValuePath,
    } = useCollectionQuery(dataType.name, project, element, elementPath, {
      additionalDeps,
      after,
      before,
      autoRefresh: layout !== CHARTS && !!dataList.limit,
      countOnly: false,
      customFilters: combinedFilters,
      filter: dataList.filter,
      limit: !layoutUsesAllRecords ? dataList.limit : undefined,
      orderBy,
      skipLambdas:
        layoutUsesAllRecords || !dataList.limit || dataList.limit > 1000,
    });

    const hideCollectionIfEmpty = useMemo(
      () => hideIfEmpty && edges.length === 0 && !isFilteredByUser,
      [hideIfEmpty, edges.length, isFilteredByUser],
    );
    const bulkActionsEnabled = get(element, 'props.bulkActionsEnabled', false);
    const setSelectAllRows = useCallback(
      (selectAll) =>
        setSelectedRows((rows) =>
          selectAll
            ? edges.map((edge: RecordEdge) => edge.node)
            : edges.length === rows.length
              ? []
              : rows,
        ),
      [edges],
    );

    const allRowsSelected = useMemo(
      () => edges.length > 0 && edges.length === selectedRows.length,
      [edges, selectedRows],
    );

    const recordsSelected = useMemo(
      () => selectedRows.length > 0 || allRowsSelected,
      [selectedRows, allRowsSelected],
    );

    const recordScope = useCallback(
      (record) => ({
        ...formatRecordScope(record),
        ...scope,
      }),
      [formatRecordScope, scope],
    );

    const oneClickActionButtons = useMemo(() => {
      if (loading || selectedRows.length === 0) {
        return [];
      }

      return recordActionButtons.filter(
        (recordActionButton) =>
          recordActionButton.execution === ONE_CLICK &&
          selectedRows.every((selectedRow) =>
            shouldRenderComponent(
              currentUser,
              recordActionButton.visibilityRules ?? {},
              project,
              recordScope(selectedRow),
              customRulesEnabled,
            ),
          ),
      );
    }, [
      loading,
      selectedRows,
      recordActionButtons,
      currentUser,
      customRulesEnabled,
      project,
      recordScope,
    ]);

    const onDeleteRecord = useCallback(
      (record: BaseRecord, onNext: any) => {
        removeRecordFromCollection(record.id);
        setSelectedRows((selectedRows: BaseRecord[]) =>
          selectedRows.filter((selectedRow) => selectedRow.id !== record.id),
        );
        onNext();
      },
      [removeRecordFromCollection],
    );

    const handleBulkActionButtonClick = useCallback(
      (actionButton) =>
        edges
          .filter((edge: RecordEdge) =>
            selectedRows.find((row) => row.id === edge.node.id),
          )
          .map((edge: RecordEdge) => {
            const actionButtonElement = {
              props: { actionButtons: [actionButton] },
              type: ACTION_BUTTONS,
            };
            const nextScope = recordScope(edge.node);
            const { actionButtons: resolvedActionButtons } =
              reduceDynamicPropValues(
                baseElementsConfig[ACTION_BUTTONS].props,
                actionButtonElement.props,
                nextScope,
                actionButtonElement,
                project,
                elementPath,
                true,
                () => true,
                getLocaleName(),
                locale,
              );

            return queueAction({
              executionId: shortid.generate(),
              executionType: ONE_CLICK,
              action: resolvedActionButtons[0],
              dataType,
              record: edge.node,
              scope: nextScope,
              onDeleteRecord,
            });
          }),
      [
        edges,
        selectedRows,
        recordScope,
        project,
        elementPath,
        locale,
        queueAction,
        dataType,
        onDeleteRecord,
      ],
    );
    const additionalFiltersPerLine = searchEnabled ? 1 : 2;
    const filtersOnNewLine =
      validFilters.length > additionalFiltersPerLine ||
      actionButtons.length >= additionalFiltersPerLine;

    const showMobileActionButtons =
      (isSmScreen && actionButtons && actionButtons.length > 0) ||
      showExportButton ||
      (showImportButton && permissions.create);

    const showNewButton = useMemo(
      () =>
        layout !== CHARTS &&
        layout !== PIVOT_TABLE &&
        permissions.create &&
        (viewRootPathname || editorMode) &&
        newButtonVisible &&
        !hideNewButton,
      [
        editorMode,
        hideNewButton,
        layout,
        newButtonVisible,
        permissions,
        viewRootPathname,
      ],
    );

    if (hideCollectionIfEmpty && !editorMode) {
      return null;
    }

    return (
      <div
        className={classNames(
          className,
          'collection-view',
          `collection-view-${layout.toLowerCase()}`,
          {
            'opacity-50': hideCollectionIfEmpty && editorMode,
          },
        )}
        ref={ref}
        onClick={onClick}
      >
        {(title ||
          subtitle ||
          coverPhoto ||
          validFilters.length > 0 ||
          permissions.create) && (
          <Title
            subtitle={{
              hidden: !subtitle,
              value: subtitle,
            }}
            title={{
              hidden: !title,
              value: title,
            }}
            searchEnabled={searchEnabled}
            coverPhoto={{
              hidden: !coverPhoto,
              value: coverPhoto,
            }}
            className={classNames(filtersOnNewLine ? 'mb-2' : 'mb-4', {
              'px-8 sm:px-4': [
                TABLE_FULL,
                SPLIT,
                MAP,
                CALENDAR,
                TIMELINE,
                GANTT,
                BOARD,
              ].includes(layout),
              'px-4 sm:p-0':
                layout === ROWS || layout === TABLE || layout === PIVOT_TABLE,
            })}
          >
            {(!filtersOnNewLine || isSmScreen) && (
              <CollectionFilters
                filterValues={filterValues}
                project={project}
                search={search}
                searchEnabled={searchEnabled}
                validFilters={validFilters}
                updateFilterValue={updateFilterValue}
              />
            )}
            {!isSmScreen && actionButtons && (
              <CollectionActionButtons
                actionButtons={actionButtons}
                dataType={dataType}
                editorMode={editorMode}
                elementPath={elementPath}
                project={project}
                scope={scope}
              />
            )}
            {layout !== CHARTS && !isSmScreen && isExportButtonVisible && (
              <ExportButton
                additionalDeps={additionalDeps}
                dataList={dataList}
                dataType={dataType}
                customFilters={combinedFilters}
                elementPath={elementPath}
                fieldConfigs={fieldConfigs}
                project={project}
                fileNamePrefix={title}
                text={exportButtonText}
              />
            )}
            {layout !== CHARTS &&
              !isSmScreen &&
              isImportButtonVisible &&
              permissions.create && (
                <ImportButton
                  buttonText={importButtonText}
                  dataType={dataType}
                  fields={importFields}
                  project={project}
                  variant="secondary"
                />
              )}
            <div className="flex items-center">
              {showNewButton && (
                <Tooltip
                  content={
                    <div
                      className={classNames('flex flex-col', {
                        dark: isDarkModeEnabled,
                      })}
                    >
                      <div className="bg-gray-100 p-4 dark:bg-gray-500 dark:text-white">
                        <span>
                          {getText(
                            { dataType: dataType.display },
                            'elements.VIEW.buttons.newButton.noView.description',
                          )}
                        </span>
                      </div>
                      <div className="flex justify-end bg-gray-50 px-4 py-2 dark:border-t dark:border-gray-600 dark:bg-gray-600">
                        <Button
                          size="sm"
                          className="ml-auto"
                          onClick={onAddMissingView}
                          variant="secondary"
                        >
                          {getText(
                            'elements.VIEW.buttons.newButton.noView.cta',
                          )}
                        </Button>
                      </div>
                    </div>
                  }
                  offset={[0, 0]}
                  p={0}
                  placement="bottom-end"
                  disabled={!editorMode || !!viewRootPathname}
                  isOpen={true}
                  showArrow={false}
                >
                  <Link
                    to={newButtonLink as string}
                    className={classNames('new-button whitespace-nowrap', {
                      'opacity-50': !viewRootPathname,
                    })}
                    onClick={() => null}
                  >
                    <Button
                      className={classNames(
                        'flex h-8 items-center space-x-2 rounded-bl-lg rounded-tl-lg',
                        {
                          'rounded-br-lg rounded-tr-lg':
                            !showEditRelatedRecordButton,
                          'sm:rounded-br-none sm:rounded-tr-none':
                            showMobileActionButtons,
                        },
                      )}
                      rounded={false}
                    >
                      <IconPlus size={14} className="opacity-75" />
                      <span>
                        {newButtonText ||
                          getText(
                            { dataType: dataType.display },
                            'core.COLLECTION.form.new',
                          )}
                      </span>
                    </Button>
                  </Link>
                </Tooltip>
              )}
              {showMobileActionButtons && (
                <MobileActionButtonsWrapper
                  rounded={!showEditRelatedRecordButton}
                  newButtonVisible={newButtonVisible}
                >
                  {layout !== CHARTS && isExportButtonVisible && (
                    <ExportButton
                      additionalDeps={additionalDeps}
                      buttonType="text"
                      dataList={dataList}
                      dataType={dataType}
                      customFilters={combinedFilters}
                      elementPath={elementPath}
                      fieldConfigs={fieldConfigs}
                      project={project}
                      fileNamePrefix={title}
                      text={exportButtonText}
                    />
                  )}
                  {layout !== CHARTS &&
                    isImportButtonVisible &&
                    permissions.create && (
                      <ImportButton
                        buttonText={importButtonText}
                        buttonType="text"
                        dataType={dataType}
                        fields={importFields}
                        project={project}
                        variant="secondary"
                      />
                    )}
                  <CollectionActionButtons
                    actionButtons={actionButtons}
                    buttonType="text"
                    dataType={dataType}
                    editorMode={editorMode}
                    elementPath={elementPath}
                    project={project}
                    scope={scope}
                  />
                </MobileActionButtonsWrapper>
              )}
              {layout !== CHARTS && showEditRelatedRecordButton && (
                <AddRelatedRecordButton
                  config={get(editRelatedRecordButtons, ['config'])}
                  dataList={dataList}
                  dataType={dataType}
                  newButtonVisible={
                    editorMode
                      ? newButtonVisible
                      : newButtonVisible && viewRootPathname
                  }
                  project={project}
                  refetch={refetch}
                  rootDataType={rootDataType}
                  scope={scope}
                  surface={LIGHT}
                />
              )}
            </div>
          </Title>
        )}
        {filtersOnNewLine && !isSmScreen && (
          <div
            className={classNames('my-3 flex flex-wrap items-end justify-end', {
              'px-4': layout === TABLE_FULL,
            })}
          >
            <CollectionFilters
              filterValues={filterValues}
              project={project}
              search={search}
              searchEnabled={searchEnabled}
              validFilters={validFilters}
              updateFilterValue={updateFilterValue}
            />
          </div>
        )}
        {bulkActionsEnabled && recordsSelected && (
          <BulkActionsBar
            elementId={element.id}
            layout={layout}
            activeActionItem={activeActionItem}
            elementPath={elementPath}
            handleBulkActionButtonClick={handleBulkActionButtonClick}
            oneClickActionButtons={oneClickActionButtons}
            project={project}
            scope={scope}
            selectedRows={selectedRows}
            setSelectAllRows={setSelectAllRows}
            setSelectedRows={setSelectedRows}
          />
        )}
        <Box
          className={classNames(
            COLLECTION_WRAPPER_STYLES[
              layout as keyof typeof COLLECTION_WRAPPER_STYLES
            ](isDarkModeEnabled as boolean) ||
              COLLECTION_WRAPPER_STYLES[ROWS](isDarkModeEnabled as boolean),
            {
              'flex-col': layout === COLUMNS && canGroup,
            },
            wrapperClassName,
          )}
        >
          <CollectionDataBody
            actionButtons={recordActionButtons}
            additionalDeps={additionalDeps}
            after={after}
            allRowsSelected={allRowsSelected}
            before={before}
            bulkActionsEnabled={bulkActionsEnabled}
            calendarDate={calendarDate}
            calendarView={calendarView}
            charts={charts}
            customFilters={combinedFilters}
            dataList={dataList}
            dataType={dataType}
            dateEnd={dateEnd}
            dateEndField={dateEndField}
            dateStart={dateStart}
            dateStartField={dateStartField}
            edges={edges}
            editorMode={editorMode}
            editRelatedRecordButtons={editRelatedRecordButtons}
            element={element}
            elementPath={elementPath}
            EmptyState={EmptyState}
            enableDragAndDropEdit={enableDragAndDropEdit}
            endTime={endTime}
            error={error}
            fieldConfigs={fieldConfigs}
            formatRecordScope={formatRecordScope}
            ganttDependency={ganttDependency}
            groupByFields={groupByFields}
            groupOptions={groupOptions}
            handleSetOrderBy={handleSetOrderBy}
            hideEmptyGroups={hideEmptyGroups}
            innerClassName={innerClassName}
            isRecordView={isRecordView}
            layout={layout}
            limitPerGroup={limitPerGroup}
            loading={loading}
            map={map}
            mapLatField={mapLatField}
            mapLngField={mapLngField}
            maxStickyColumnIndex={maxStickyColumnIndex}
            newButtonLink={newButtonLink}
            newButtonVisible={showNewButton}
            nodeQueryObject={nodeQueryObject}
            orderBy={orderBy}
            pageInfo={pageInfo}
            parentValuePath={parentValuePath}
            permissions={permissions}
            pivotTable={pivotTable}
            project={project}
            qsSuffix={qsSuffix}
            rawData={rawData}
            recordConfig={record}
            recordQueryString={recordQueryString}
            recordTitle={recordTitle}
            refetch={refetch}
            rootDataType={rootDataType}
            rowLink={rowLink}
            scope={scope}
            selectedRows={selectedRows}
            setPaginationQuery={setPaginationQuery}
            setSelectAllRows={setSelectAllRows}
            setSelectedRows={setSelectedRows}
            showCardHeroImage={showCardHeroImage}
            showTableSummary={showTableSummary}
            startTime={startTime}
            totalCount={totalCount}
            transformRecordScope={transformRecordScope}
            viewRootPathname={viewRootPathname}
          />
        </Box>
      </div>
    );
  },
);

export default ViewCollectionBody;
